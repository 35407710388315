

import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../views/Home/Home.vue"

import Layout from '../views/Layout/Layout'


Vue.use(VueRouter)


// 解决vue-router在3.0版本以上重复点报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}






//1.创建路由组件
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect:'/Home',//重定向
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/Logisticsplan',
    name: 'Logisticsplan',
    component: () => import('@/views/Logisticsplan/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/SmsNotification',
    name: 'SmsNotification',
    component: () => import('@/views/SmsNotification/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/TiktokPromotion',
    name: 'TiktokPromotion',
    component: () => import('@/views/TiktokPromotion/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/SmsVerification',
    name: 'SmsVerification',
    component: () => import('@/views/SmsVerification/index'),
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/VideoMms',
    name: 'VideoMms',
    component: () => import('@/views/VideoMms/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/SmsMarketing',
    name: 'SmsMarketing',
    component: () => import('@/views/SmsMarketing/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/MultimediaVideo',
    name: 'MultimediaVideo',
    component: () => import('@/views/MultimediaVideo/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/FlashMessage',
    name: 'FlashMessage',
    component: () => import('@/views/FlashMessage/index'),
    meta: {
      title: "中融网络科技"
    }

  },
  {
    path: '/TiktokMarketing',
    name: 'TiktokMarketing',
    component: () => import('@/views/TiktokMarketing/index'),
    meta: {
      title: "中融网络科技"
    }

  },

  {
    path: '/TiktokAdvertisement',
    component: () => import('@/views/TiktokAdvertisement/index'),
    name: 'TiktokAdvertisement',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/Dongchedi',
    component: () => import('@/views/Dongchedi/index'),
    name: 'Dongchedi',
    meta: {
      title: "中融网络科技"
    }
  },

{
  path:'/ToolList',
  name: 'ToolList',
  component: Layout,
  hidden: true,
  meta: {
    title: "中融工具"
  },
  children: [
    {
      path: 'watermark',
      component: () => import('@/views/ToolList/watermark'),
      name: 'watermark',
      meta: {
        title: "新闻"
      }
    },
    {
      path: 'wordPdf',
      component: () => import('@/views/ToolList/wordPdf'),
      name: 'wordPdf',
      meta: {
        title: "新闻"
      }
    },
  ]
},

  {
    path: '/TextNews',
    name: 'TextNews',
    component: Layout,
    hidden: true,
    meta: {
      title: "中融网络科技"
    },
    children: [
      {
        path: 'news1',
        component: () => import('@/views/TextNews/news1'),
        name: 'News1',
        meta: {
          title: "新闻"
        }
      },
      {
        path: 'news2', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news2'), // 保持不变或更改为您的News2组件  
        name: 'News2', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "新闻" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news3', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news3'), // 保持不变或更改为您的News2组件  
        name: 'News2', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "新闻" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news4', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news4'), // 保持不变或更改为您的News2组件  
        name: 'News4', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "新闻" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news5', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news5'), // 保持不变或更改为您的News2组件  
        name: 'News5', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "新闻" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news6', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news6'), // 保持不变或更改为您的News2组件  
        name: 'News6', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "新闻" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news7', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news7'), // 保持不变或更改为您的News2组件  
        name: 'News7', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "行业动态" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news8', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news8'), // 保持不变或更改为您的News2组件  
        name: 'News8', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "行业动态" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news9', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news9'), // 保持不变或更改为您的News2组件  
        name: 'News9', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "行业动态" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news10', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news10'), // 保持不变或更改为您的News2组件  
        name: 'News10', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "行业动态" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news11', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news11'), // 保持不变或更改为您的News2组件  
        name: 'News11', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "行业动态" // 可以为子路由设置不同的标题  
        }
      },
      {
        path: 'news12', // 修改path为唯一的值  
        component: () => import('@/views/TextNews/news12'), // 保持不变或更改为您的News2组件  
        name: 'News12', // name保持不变，但因为它是在children数组中，所以它是唯一的  
        meta: {
          title: "行业动态" // 可以为子路由设置不同的标题  
        }
      },
    ]
  },

  {
    path: '/FigureWorm',
    component: () => import('@/views/FigureWorm/index'),
    name: 'FigureWorm',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/TomatoNovel',
    component: () => import('@/views/TomatoNovel/index'),
    name: 'TomatoNovel',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/Zhuxiaobang',
    component: () => import('@/views/Zhuxiaobang/index'),
    name: 'Zhuxiaobang',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/WatermelonVideo',
    component: () => import('@/views/WatermelonVideo/index'),
    name: 'WatermelonVideo',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/Pangolin',
    component: () => import('@/views/Pangolin/index'),
    name: 'Pangolin',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/TodaysHeadlines',
    component: () => import('@/views/TodaysHeadlines/index'),
    name: 'TodaysHeadlines',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/GamePlan',
    component: () => import('@/views/GamePlan/index'),
    name: 'GamePlan',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/TencentMarketing',
    component: () => import('@/views/TencentMarketing/index'),
    name: 'TencentMarketing',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/CateringSolutions',
    component: () => import('@/views/CateringSolutions/index'),
    name: 'CateringSolutions',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/CommerceSolutions',
    component: () => import('@/views/CommerceSolutions/index'),
    name: '电商行业解决方案',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/Express',
    component: () => import('@/views/Express/index'),
    name: 'Express',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/RealEstate',
    component: () => import('@/views/RealEstate/index'),
    name: 'RealEstate',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/WebCases',
    component: () => import('@/views/WebCases/index'),
    name: 'WebCases',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/AppletCase',
    component: () => import('@/views/AppletCase/index'),
    name: 'AppletCase',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/SystemCase',
    component: () => import('@/views/SystemCase/index'),
    name: 'SystemCase',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/AppCase',
    component: () => import('@/views/AppCase/index'),
    name: 'AppCase',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/SoftwareDevelopment',
    component: () => import('@/views/SoftwareDevelopment/index'),
    name: 'SoftwareDevelopment',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/CompanyNews',
    component: () => import('@/views/CompanyNews/index'),
    name: 'CompanyNews',
    meta: {
      title: "中融网络科技"
    }
  },

  {
    path: '/ToolCenter',
    component: () => import('@/views/ToolCenter/index'),
    name: 'ToolCenter',
    meta: {
      title: "中融网络科技"
    }
  },
  {
    path: '/CompanyProfile',
    component: () => import('@/views/CompanyProfile/index'),
    name: 'CompanyProfile',
    meta: {
      title: "中融网络科技"
    }
  },
]




const router = new VueRouter({
  // mode:'history',
  routes
})

router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

export default router


