<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-20 18:00:36
 * @LastEditors: 张新泽 11746616+zhangxinzesad@user.noreply.gitee.com
 * @LastEditTime: 2024-06-03 11:21:45
 * @FilePath: \vue_test\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
 <div id="app">
      <navTop  />
    <router-view />
    <APPbottom class="display-PH" />
  </div>
</template>

<script>
import navTop from '@/components/nav/index.vue'
import APPbottom from '@/components/bottom/index.vue'


export default {
  name: 'App',
  components: {
   navTop ,
   APPbottom
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
   
}

a {
   text-decoration: none; /* 去除链接的下划线 */
}
ol, ul {
    list-style: none;
}

body, html {
    overflow-x: hidden;
} 

img, video {
    max-width: 100%;
    vertical-align: middle;
    /* margin:  0 auto; */
}

</style>
