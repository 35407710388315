
<template>
  <div class="header-box">
    <div class="header web-container ">
      <div class="logo-box">
        <a href="#/" class="logo"
          >
          <img class="sj-img" src="../../assets/img/logo1.png" alt="">
          </a
        >
        <!-- <span class="video-img"
          >logo</span> -->
      </div>
      <div class="nav hide-new">
        <div class="nav-item position-inherit">
          <a href="javascript:;" class="tit"
            >产品中心<i class="el-icon-arrow-down"></i
          ></a>
          <div class="item-cont">
            <div class="nav-childs">
              <div class="nav-col">
                <div class="menu2">
                  <div class="menu-tit">云通讯</div>
                  <div class="menu-list">
                    <a href="#/SmsNotification" class=""
                      >短信通知<!----><span class="tag-hot">HOT</span
                      ><!----><!----></a
                    ><a href="#/SmsVerification" class=""
                      >短信验证码<!----><!----><!----><!----></a
                    ><a href="#/SmsMarketing" class=""
                      >短信营销<!----><span class="tag-hot">HOT</span
                      ><!----><!----></a
                    ><a href="#/MultimediaVideo" class=""
                      >视频彩信<!----><span class="tag-hot">HOT</span
                      ><!----><!----></a
                    ><a href="#/FlashMessage" class=""
                      >霸屏闪信<!----><!----><!----><!----></a
                    >
                  </div>
                </div>
              </div>
              <div class="nav-col">
                <div class="menu2">
                  <div class="menu-tit">抖音服务</div>
                  <div class="menu-list">
                    <a href="#/TiktokMarketing" class=""
                      >抖音场景营销</a
                    >
                  </div>
                </div>
               
              <div class="menu2">
                  <div class="menu-tit">腾讯服务</div>
                  <div class="menu-list">
                    <a href="#/TencentMarketing" class="" 
                      >腾讯全场景营销</a
                    >
                  </div>
                </div>

              </div>
             
              <div class="nav-col">
                <div class="menu2">
                  <div class="menu-tit">软件开发</div>
                  <div class="menu-list">
                    <a href="#/WebCases" class=""
                      >网页设计案例<!----><!----><!----><!----></a
                    ><a href="#/AppletCase" class=""
                      >小程序案例<!----><span class="tag-hot">HOT</span
                      ><!----><!----></a
                    ><a href="#/SystemCase" class=""
                      >系统案例<!----><!----><!----><!----></a
                    ><a href="#/SoftwareDevelopment" class=""
                      >软件开发<!----><!----><!----><!----></a
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="nav-col">
                <div class="menu2">
                  <div class="menu-tit" >  营销工具</div>
                  <div class="menu-list">
                  
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="nav-item">
          <a href="javascript:;" class="tit"
            > <a href="#/ToolList/watermark"> 工具中心</a><i class="el-icon-arrow-down"></i
          ></a>
          <div class="item-cont small">
            <div class="menu">
              <p><a href="#">视频去水印</a></p>
              <p><a href="#">WORD转PDF</a></p>
              <p><a href="#">图片变清晰</a></p>
              <p><a href="#">图片去水印</a></p>
              <p><a href="#">图片设计</a></p>
              <p><a href="#">视频剪辑</a></p>
            </div>
          </div>
        </div>
        <div class="nav-item">
          <a href="javascript:;" class="tit"
            >新闻资讯<i class="el-icon-arrow-down"></i
          ></a>
          <div class="item-cont small">
            <div class="menu">
              <p><a href="#/CompanyNews">最新资讯</a></p>
              <!-- <p><a href="#">行业动态</a></p> -->
           
            </div>
          </div>
        </div>
         <div class="nav-item">
          <a href="javascript:;" class="tit"
            >方案解决<i class="el-icon-arrow-down"></i
          ></a>
          <div class="item-cont small">
            <div class="menu">
              <p><a href="#/CateringSolutions">餐饮解决方案</a></p>
              <p><a href="#/CommerceSolutions">电商解决方案</a></p>
              <p><a href="#/RealEstate">房产解决方案</a></p>
              <p><a href="#/Express">快递物流解决方案</a></p>
              <p><a href="#/GamePlan">游戏行业解决方案</a></p>

            </div>
          </div>
        </div>
        
      
    
        <div class="nav-item">
          <a href="javascript:;" class="tit"
            >关于我们<i class="el-icon-arrow-down"></i
          ></a>
          <div class="item-cont small">
            <div class="menu">
              <p><a href="#/CompanyProfile">企业简介</a></p>
           
            </div>
          </div>
        </div>

        
      </div>
      <div class="user">
        <!-- <a href="javascript:;" class="logo_btn">登录</a
        > -->
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
    return {
         navs: [
        {
          name: "产品中心",
          navContent: [
            { text: "短信通知",path:'SmsNotification' },
            { text: "抖音推广" ,path:'TiktokPromotion'},
            { text: "短信验证码",path:'SmsVerification' },
            { text: "朋友圈推广" },
            { text: "短信营销",path:'SmsMarketing'},
            { text: "小程序定制" },
            { text: "视频彩信",path:'VideoMms'},
            { text: "企业网站设计" },
            { text: "霸屏闪信" },
            { text: "政企合作" },
          ],
        },
        {
          name: "工具中心",
          navContent: [
            { text: "视频去水印" },
            { text: "WORD转PDF" },
            { text: "图片变清晰" },
            { text: "图片设计" },
            { text: "视频剪辑" },
          ],
        },
         {
          name: "新闻资讯",
          navContent: [{ text: "公司新闻" }, { text: "行业动态" }],
        },
        { name: "联系我们", navContent: [{ text: "在线咨询" }] },
         { name: "关于我们", navContent: [{ text: "企业简介" }] },

      ],
    
    }
},



};
</script>

<style scoped>

.header .nav .nav-item .tit{
  padding: 0 15px !important ;
}

.header-box {
  width: 100%;
  height: 60px;
  font-size: 14px;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
a {
    color: #333;
    transition: all .3s ease 0s;
}
.web-page .header-box.activity {
  background: none !important;
  box-shadow: none;
  position: absolute !important;
}
.web-page .header-box.activity .header .nav .nav-item .tit,
.web-page .header-box.activity .header .user a {
  color: #fff !important;
}
.web-page.hasActivity {
  padding-top: 130px;
}
.web-page.hasActivity .header-box {
  top: 70px;
}
.web-page.no-pad {
  padding-top: 0;
}
.web-page.no-pad.hasActivity {
  padding-top: 70px;
}
.web-page.no-pad.hasActivity .doc_header,
.web-page.no-pad.hasActivity .header_bg {
  top: 70px;
}
.web-page.no-pad .doc_header,
.web-page.no-pad .header_bg {
  top: 0;
}
.web-container {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 600px){
  .hide-new {
    display: none !important;
  }
  .sj-img{
    margin-left: 20px !important;
  }
}
@media (min-width: 640px) {
  .hide-new  {
        /* display: none !important; */

  }
}
.header_bg {
  width: 100%;
  height: 60px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 999;
}
.header_bg .header .nav .nav-item .tit {
  color: #fff;
}
.header_bg .header .nav .nav-item .tit2:hover {
  color: #000;
}
.header_bg .header .user,
.header_bg .header .user .logo_btn {
  color: #fff;
}
.header_bg .resgister_active {
  height: 33px;
  border-radius: 4px;
  color: #7cbe27;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.header_bg .resgister_active img {
  height: 18px;
  margin-right: 2px;
}
.doc_header {
  width: 100%;
  height: 60px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 999;
}
.doc_header .header .nav .nav-item .tit {
  color: #fff;
}
.doc_header .header .nav .nav-item .tit:hover {
  color: #20291f;
}
.doc_header .header .user,
.doc_header .header .user .logo_btn {
  color: #fff;
}
.doc_header .resgister_active {
  height: 33px;
  border-radius: 4px;
  color: #7cbe27;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.doc_header .resgister_active img {
  height: 18px;
  margin-right: 2px;
}
.header {
  height: 60px;
  justify-content: space-between;
  align-items: center;
}
.header,
.header .logo-box {
  position: relative;
  display: flex;
}
.header .logo-box {
  height: 33px;
  width: 253px;
  min-width: 170px;
}
.header .logo-box .logo {
  display: block;
  overflow: hidden;
  float: left;
  margin-right: 10px;
}
.header .logo-box .logo img {
  max-height: 33px;
  display: block;
}
.header .logo-box .icon {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: -5px;
  right: -40px;
}
.header .logo-box .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.header .logo-box .slogan {
  display: inline-flex;
  height: 40px;
  align-items: center;
  width: 160px;
}
.header .logo-box .video-img {
  display: block;
  cursor: pointer;
}
.header .logo-box .video-img img {
  display: block;
  height: 100%;
}
.header .nav {
  display: flex;
  max-width: 1200px;
  flex: 1;
  justify-content: center;
}
.header .nav .nav-item {
  width: auto;
  height: 60px;
  position: relative;
}
.header .nav .nav-item.position-inherit {
  position: inherit !important;
}
.header .nav .nav-item .tit {
  width: auto;
  height: 60px;
  font-size: 16px;
  color: #20291f;
  display: flex;
  align-items: center;
  padding: 0 25px;
}
.header .nav .nav-item .tit i {
  margin-left: 5px;
  transition: all 0.3s;
}
.header .nav .nav-item .tit:hover {
  color: #13a9ff;
}
.header .nav .nav-item .tit:hover i {
  transform: rotate(180deg);
}
.header .nav .nav-item .item-cont {
  width: 1200px;
  position: absolute;
  top: 59px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  left: 50%;
  margin-left: -600px;
  border-radius: 10px;
  display: none;
}
.header .nav .nav-item .item-cont.small {
  width: 200px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  top: 59px;
  padding: 12px 30px;
}
.header .nav .nav-item .item-cont.small .menu {
  width: 100%;
}
.header .nav .nav-item .item-cont.small .menu p {
  width: 100%;
  height: 36px;
  line-height: 36px;
}
.header .nav .nav-item .item-cont.small .menu p a {
  display: block;
}
.header .nav .nav-item .item-cont.small .menu a:hover {
  color: #13a9ff;
}
.header .nav .nav-item .item-cont .nav-childs {
  display: flex;
}
.header .nav .nav-item .item-cont .menu {
  width: 310px;
  margin-right: 35px;
}
.header .nav .nav-item .item-cont .menu:last-child {
  margin-right: 0;
}
.header .nav .nav-item .item-cont .menu .menu-tit {
  width: 100%;
  height: 50px;
  position: relative;
  padding-left: 60px;
  margin-bottom: 20px;
}
.header .nav .nav-item .item-cont .menu .menu-tit .icon {
  display: flex;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  box-shadow: 0 3px 6px rgba(251, 125, 36, 0.16);
  border-radius: 8px;
  overflow: hidden;
}
.header .nav .nav-item .item-cont .menu .menu-tit .name {
  font-size: 18px;
  line-height: 20px;
  display: block;
  margin-bottom: 6px;
  font-weight: bolder;
}
.header .nav .nav-item .item-cont .menu .menu-tit .text {
  color: #b3b3b3;
  font-size: 14px;
}
.header .nav .nav-item .item-cont .menu .list {
  border-top: 1px solid #ededed;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
.header .nav .nav-item .item-cont .menu .list p {
  width: 50%;
  height: 40px;
  line-height: 30px;
}
.header .nav .nav-item .item-cont .menu .list p a {
  position: relative;
  margin-bottom: 20px;
}
.header .nav .nav-item .item-cont .menu .list p a .label_img {
  height: 15px;
  position: absolute;
  top: -2px;
  right: -26px;
}
.header .nav .nav-item .item-cont .menu .list a:hover {
  color: #13a9ff;
}
.header .nav .nav-item .item-cont .nav-col {
  width: 20%;
  padding: 0 20px;
}
.header .nav .nav-item .item-cont .menu2 {
  margin-bottom: 50px;
}
.header .nav .nav-item .item-cont .menu2:last-child {
  margin-bottom: 0;
}
.header .nav .nav-item .item-cont .menu2 .menu-tit {
  font-size: 16px;
  color: #333;
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  line-height: 16px;
}
.header .nav .nav-item .item-cont .menu2 .menu-list {
  padding-top: 10px;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a {
  display: block;
  font-size: 14px;
  color: #333;
  height: 30px;
  line-height: 30px;
  margin-bottom: 5px;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a:last-child {
  margin-bottom: 0;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a:hover {
  color: #13a9ff;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a.disabled {
  color: #999;
  cursor: no-drop;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-disabled,
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-free,
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-hot,
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-new {
  margin-left: 4px;
  font-size: 12px;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-new {
  color: #f80;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-hot {
  color: #e1261c;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-disabled {
  color: #ababab;
  background: #f5f5f5;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  padding: 0 4px;
}
.header .nav .nav-item .item-cont .menu2 .menu-list a .tag-free {
  color: #13a9ff;
}
.header .nav .nav-item:hover .tit {
  color: #13a9ff;
}
.header .nav .nav-item:hover .item-cont {
  display: block;
}
.header .nav .nav-item:first-child .tit {
  padding-left: 0;
  padding-right: 25px;
}
.header .user {
  display: flex;
  line-height: 33px;
  width: 253px;
  min-width: 170px;
  justify-content: flex-end;
}
.header .user a {
  height: 33px;
  font-size: 16px;
  padding: 0 16px;
}
.header .user a.logo_btn {
  margin-right: 10px;
  color: #20291f;
}
.header .user .zc_active {
  border: 1px solid #7cbe27;
}
.header .user .active {
  height: 33px;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #13a9ff;
}
.header .user .active img {
  height: 18px;
  margin-right: 2px;
}
.header .openMenu {
  display: none;
}
</style>