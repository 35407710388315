
<template>
  <div>
    <!-- <navTop/> -->
     <!-- <navTop/> -->

    <div class="top" :style="{backgroundImage:`url(${header})`}">
     

      <div style="" class="top-dinwei">
        
        <div class="top-text" >流量创造价值</div>
        <div class="top-text2">流量助力品牌成长 价值持续高效转化</div>
      </div>
    </div>
  <!-- <div class="conter">

    <div class="content">

      <div  class="content-flex">
          <div class="content-flex-width"  >

   
      <div style="width:100%;">

      <div class="content-top">
        <div style="text-align: center">
          <div class="content-top-text1" >
            全场景需求覆盖
          </div>
          <div class="content-top-text2">定制您的多种营销目标</div>
        </div>
      </div>

   
            
      <div style="">
        <div class="content-classification">
          <div
            class="content-classification-item"
            :class="glide == index ? 'glide' : ''"
            v-for="(item, index) in informationsAll"
            :key="index"
            @mouseenter="onGlide(index)"
          >
            <div>
              <img
                :src="item.src"
                style="width: 100px; height: 100px"
                alt=""
              />
            </div>
            <div class="content-classification-item-text">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <div style="">
        <div class="content-classification-bianhua">
          <div
            style="
              width: 25%;
              background-color: #fff;
              hight: 600px;
              opacity: 0.7;
                border-radius: 30px 0 0 30px;
  overflow: hidden;
            "
          >
            <div
              v-for="(item, indexs) in informationsAll[glide].informations"
              :key="indexs"
            >
              <div>
                <div class="content-classification-bianhua-left">
                  <div
                    class="content-classification-bianhua-left-item"
                    @mouseenter="onChanges(indexs, item)"
                    :class="informationNum == indexs ? 'on-selected ' : ''"
                  >
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-classification-bianhua-right">
            <div
              v-for="(element, index) in informationsAll[glide].informations"
              :key="index"
            >
              <div v-if="informationNum == index" class="content-classification-bianhua-right-flex">

                <div class="content-classification-bianhua-right-left">

                  <div
                  class="content-classification-bianhua-right-text-top"
                  style="text-align: left"
                >
                  {{ element.top }}
                </div>

                <div
                  class="content-classification-bianhua-right-text"
                  style="margin-top: 20px; width: 500px; text-align: left"
                >
                  {{ element.content }}
                </div>

                <div
                  class="content-classification-bianhua-right-text"
                  style="margin-top: 20px; width: 500px; text-align: left"
                >
                  {{ element.content2 }}
                </div>
 
                </div>

        


              </div>
            </div>

            <div class="content-classification-bianhua-right-img">
                 <img :src="informationsAll[glide].informations[informationNum].src" style="width:300px;height:550px;" alt="">
            </div>


         



          </div>


        </div>
      </div>

     
       </div>
    </div>

      </div>

  <div class="content-flex">
    <div class="content-bottom-width" >
  <div class="content-bottom">
        <div style="display: flex; justify-content: center">
          <div>
            <div class="content-top-text1">五大核心优势</div>
            <div class="content-top-text2">
              从广告创意制作到效果优化，智能工具帮您一站式完成，让您的推广更加简单
            </div>
          </div>
        </div>

        <div class="content-bottom-content">
          <div
            class="content-bottom-content-item"
            :class="determine == 1 ? 'determine' : ''"
          >
            <div
              class="content-bottom-content-item-img"
              v-if="determine == 1 ? '' : 'determine'"
              @mouseover="handleEnter(1)"
            >
              <div style="padding: 50px 50px 50px 30px">
                <div class="content-bottom-content-item-text">智能化技术</div>
                <div
                  style="text-align: left"
                  class="content-bottom-content-item-text2"
                >
                 领先的云数据智能操作系统，AI驱动将全维度的智能系统应用到企业客户服务、营销、客户运营等各个场景，实现数据与智能一体化。
                </div>
              </div>
            </div>
            <div
              class="content-bottom-content-item-img-dis"
              v-if="determine == 1 ? 'determine' : ''"
            >
             <div style="padding: 50px 50px 50px 30px">
                <div class="content-bottom-content-item-text">智能化技术</div>
                <div
                  style="text-align: left"
                  class="content-bottom-content-item-text2"
                >
                 <div class="text-huadong">云数据智能操作系统</div>
                 <div class="text-huadong">AI全智能驱动系统</div>
                 <div class="text-huadong">系统涵盖服务全场景</div>
                 <div class="text-huadong">数据智能一体化</div>

                </div>
              </div>
            </div>
          </div>
          <div
            class="content-bottom-content-item"
            :class="determine == 2 ? 'determine' : ''"
            @mouseleave="handleleave()"
          >
            <div
              class="content-bottom-content-item-img2"
              @mouseover="handleEnter(2)"
            >
              <div style="padding: 50px 0px 0px 0px">
                <div class="content-bottom-content-item-text">用户信息安全</div>
                <div
                  style="text-align: left;border:"
                  class="content-bottom-content-item-text2"
                  
                >
              <div class="text-huadong">云数据智能操作系统</div>
                 <div class="text-huadong">AI全智能驱动系统</div>
                 <div class="text-huadong">系统涵盖服务全场景</div>
                 <div class="text-huadong">数据智能一体化</div>
                 
                </div>
              </div>
            </div>
            <div
              class="content-bottom-content-item-img-dis"
              v-if="determine == 2 ? 'determine' : ''"
              @mouseout="handleleave()"
            >
              <div class="cs">沧海一体</div>
              <div class="cs">可有效解决以下问题</div>
              <div class="cs">场景融合</div>
              <div class="cs">数据孤岛</div>
              <div class="cs">计算与存储资源存在浪费</div>
              <div class="cs">实现价值</div>
            </div>
          </div>
          <div
            class="content-bottom-content-item"
            :class="determine == 3 ? 'determine' : ''"
            @mouseleave="handleleave()"
          >
            <div
              class="content-bottom-content-item-img3"
              @mouseover="handleEnter(3)"
            >
              <div style="padding: 50px 0px 0px 0px">
                <div class="content-bottom-content-item-text">
                  庞大的配套应用
                </div>
                <div
                  style="text-align: left"
                  class="content-bottom-content-item-text2"
                >
                    <div class="text-huadong">云数据智能操作系统</div>
                 <div class="text-huadong">AI全智能驱动系统</div>
                 <div class="text-huadong">系统涵盖服务全场景</div>
                 <div class="text-huadong">数据智能一体化</div>
                </div>
              </div>
            </div>
            <div class="content-bottom-content-item-img-dis">
              <div class="cs">沧海一体</div>
              <div class="cs">可有效解决以下问题</div>
              <div class="cs">场景融合</div>
              <div class="cs">数据孤岛</div>
              <div class="cs">计算与存储资源存在浪费</div>
              <div class="cs">实现价值</div>
            </div>
          </div>
          <div
            class="content-bottom-content-item"
            :class="determine == 4 ? 'determine' : ''"
            @mouseleave="handleleave()"
          >
            <div
              class="content-bottom-content-item-img4"
              @mouseover="handleEnter(4)"
            >
              <div style="padding: 50px 0px 0px 0px">
                <div class="content-bottom-content-item-text">全网皆可触达</div>
                <div
                  style="text-align: left"
                  class="content-bottom-content-item-text2"
                >
                <div class="text-huadong">云数据智能操作系统</div>
                 <div class="text-huadong">AI全智能驱动系统</div>
                 <div class="text-huadong">系统涵盖服务全场景</div>
                 <div class="text-huadong">数据智能一体化</div>
                </div>
              </div>
            </div>
            <div class="content-bottom-content-item-img-dis">
              <div class="cs">沧海一体</div>
              <div class="cs">可有效解决以下问题</div>
              <div class="cs">场景融合</div>
              <div class="cs">数据孤岛</div>
              <div class="cs">计算与存储资源存在浪费</div>
              <div class="cs">实现价值</div>
            </div>
          </div>
          <div
            class="content-bottom-content-item"
            :class="determine == 5 ? 'determine' : ''"
            @mouseleave="handleleave()"
          >
            <div
              class="content-bottom-content-item-img5"
              @mouseover="handleEnter(5)"
            >
              <div style="padding: 50px 0px 0px 0px">
                <div class="content-bottom-content-item-text">大数据库支持</div>
                <div
                  style="text-align: left"
                  class="content-bottom-content-item-text2"
                >
                    <div class="text-huadong">云数据智能操作系统</div>
                 <div class="text-huadong">AI全智能驱动系统</div>
                 <div class="text-huadong">系统涵盖服务全场景</div>
                 <div class="text-huadong">数据智能一体化</div>
                </div>
              </div>
            </div>
            <div class="content-bottom-content-item-img-dis">
              <div class="cs">沧海一体</div>
              <div class="cs">可有效解决以下问题</div>
              <div class="cs">场景融合</div>
              <div class="cs">数据孤岛</div>
              <div class="cs">计算与存储资源存在浪费</div>
              <div class="cs">实现价值</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      
  
</div>
  </div> -->








  <div class="home" style="margin-top:5px">
   <div  class="content-flex">
     <div class="home-conter">
      <div class="home-conter-text1">营销新升级，触达全球30亿+用户</div>
      <div class="home-conter-text2">丰富营销资源 全球化运作 覆盖产品营销全链路</div>
      <div class="home-conter-content">
           <div class="home-conter-content-top">
            <div class="home-conter-content-top-item "   :class="glide == 1 ? 'on' : ''"     @mouseenter="onGlide(1)">
              <div class="t_fonth5">
                互联网营销场景
              </div>
              <div class="t_duanp">
               <p>通过互联网为媒介高效传播</p>
              </div>


            </div>
             <div class="home-conter-content-top-item" :class="glide == 2 ? 'on' : ''"       @mouseenter="onGlide(2)">
                <div class="t_fonth5">
                 智能化营销资源
              </div>
              <div class="t_duanp">
               <p>为企业提供更灵活的客户洞察</p>
              </div>
            </div>
             <div class="home-conter-content-top-item"  :class="glide == 3 ? 'on' : ''"      @mouseenter="onGlide(3)">
              <div class="t_fonth5">
                产品直播营销策略
              </div>
              <div class="t_duanp">
               <p>直播营销组合策略为企业实质化转型</p>
              </div>

            </div>
             <div class="home-conter-content-top-item"  :class="glide == 4 ? 'on' : ''"     @mouseenter="onGlide(4)">
              <div class="t_fonth5">
                 跨境电商营销赋能
              </div>
              <div class="t_duanp">
               <p>共谋商业发展促进经济繁荣</p>
              </div>

            </div>
           </div>

           <div class="home-conter-content-content" style="margin-top:100px;">
          <!-- 复制 -->

          <div class="t_syk1huan">

                            <ul class="t_syk1huul wow ys_fadeup4" style="visibility: visible;animation-name: ysfadeInUp;">

                            <li class="t_syk1huli " :class="glide == 1 ? 'act' : ''"  data-num="0">

                                    <div class="t_syk1huxin clearfix" style="display: flex;">

                                        <div class="t_syk1le fl">

                                            <div class="t_fonth4">互联网营销场景</div>

                                            <div class="t_duanp">

                                                <p>互联网、通信和数字媒体技术实现营销目标互联网营销的优势包括覆盖广泛、成本较低、实时互动和数据分析能力。是企业整体营销战略的重受组成部分。</p>

                                            </div>

                                            <div class="t_syk1lie yxedr_active">

                                                <ul style="list-style-type: disc;">

                                                    <li class=""><p>腾讯广告</p></li><li class=""><p>APP广告</p></li><li class=""><p>抖音广告</p></li><li class=""><p>小红书广告</p></li><li class=""><p>搜索广告</p></li><li class=""><p>百度广告</p></li>
                                                </ul>

                                            </div>

                                            <ul class="t_syk1btn clearfix" >

                                                <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan1">

                                                        <a href="/products-services/products/get">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">查看详情</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li>

                                                <!-- <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan2">

                                                        <a href="/appoint">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">预约演示</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li> -->

                                            </ul>

                                        </div>

                                        <div class="t_syk1rg fr">

                                            <div class="t_syk1pic ys_imgbox_cover">

                                                <!-- <img src="../../assets/首页图片/sy-top2-1.png" alt=""> -->

                                                <img src="../../assets/首页图片/sy-top2-1.jpg" alt="youtuget.png" style="    width: 600px;height: 400px;">

                                            </div>

                                        </div>

                                    </div>

                                </li><li class="t_syk1huli" :class="glide == 2 ? 'act' : ''"  data-num="1">

                                    <div class="t_syk1huxin clearfix" style="display: flex;">

                                        <div class="t_syk1le fl">

                                            <div class="t_fonth4">智能化营销资源</div>

                                            <div class="t_duanp">

                                                <p>客户营销+私域运营+企微SCRM,从获客引流+齐户管理+营销自动化,私越流量闭环运营全程运营自动化+赋能企业数字化营销,搭建自动化营销网络,裂变新客。</p>

                                            </div>

                                            <div class="t_syk1lie yxedr_active">

                                                <ul style="list-style-type: disc;">

                                                    <li class=""><p>私域获客</p></li><li class=""><p>智能裂变</p></li><li class=""><p>自动化运营</p></li><li class=""><p>客户管理</p></li><li class=""><p>数字化营销</p></li>
                                                </ul>

                                            </div>

                                            <ul class="t_syk1btn clearfix" >

                                                <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan1">

                                                        <a href="/products-services/products/sco">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">查看详情</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li>

                                                <!-- <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan2">

                                                        <a href="/appoint">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">预约演示</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li> -->

                                            </ul>

                                        </div>

                                        <div class="t_syk1rg fr">

                                            <div class="t_syk1pic ys_imgbox_cover">

                                                <!-- <img src="/Public/Cn/images/sy_h1.png" alt=""> -->

                                                 <img src="../../assets/首页图片/sy-top2-2.jpg" alt="youtuget.png" style="    width: 600px;height: 400px;">


                                            </div>

                                        </div>

                                    </div>

                                </li><li class="t_syk1huli" :class="glide == 3 ? 'act' : ''"  data-num="2">

                                    <div class="t_syk1huxin clearfix" style="display: flex;">

                                        <div class="t_syk1le fl">

                                            <div class="t_fonth4">产品直播营销策略</div>

                                            <div class="t_duanp">

                                                <p>直插电商通过直播的形式，将商品展示、互动、销售等多个环节融合在一起，为消费者提供了更直观、生动的购领体验，同时也为商家带来了更高的转化率和品牌影响力。</p>

                                            </div>

                                            <div class="t_syk1lie yxedr_active">

                                                <ul style="list-style-type: disc;">

                                                    <li class=""><p>品牌曝光</p></li><li class=""><p>商品展示</p></li><li class=""><p>销售环节</p></li><li class=""><p>数墅分析优化</p></li>   <li class=""><p>用户互动</p></li>
                                                </ul>

                                            </div>

                                            <ul class="t_syk1btn clearfix">

                                                <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan1">

                                                        <a href="/products-services/products/map">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">查看详情</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li>

                                                <!-- <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan2">

                                                        <a href="/appoint">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">预约演示</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li> -->

                                            </ul>

                                        </div>

                                        <div class="t_syk1rg fr">

                                            <div class="t_syk1pic ys_imgbox_cover">

                                              <img src="/Public/Cn/images/sy_h1.png" alt="">

                                                    <img src="../../assets/首页图片/sy-top2-3.jpg" alt="youtuget.png" style="    width: 600px;height: 400px;">

                                            </div>

                                        </div>

                                    </div>

                                </li><li class="t_syk1huli" :class="glide == 4 ? 'act' : ''"  data-num="3">

                                    <div class="t_syk1huxin clearfix" style="display: flex;">

                                        <div class="t_syk1le fl">

                                            <div class="t_fonth4">跨境电商营销赋能</div>

                                            <div class="t_duanp">

                                                <p>不同国境地域的交易主体之间，以电子商努的方式达成交易的一种国际商业冲破了国家间的障碍，使国际贸易走向无国界贸易，同时正在引起世界经济贸易的巨大变革。</p>

                                            </div>

                                            <div class="t_syk1lie yxedr_active">

                                                <ul style="list-style-type: disc;">

                                                    <li><p>国际B2C跨境电商平台</p></li><li><p>国际物流运输</p></li><li><p>进口跨境电商平台</p></li>
                                                    <li><p>本土店铺</p></li><li><p>本土化跨填电商平台</p></li><li><p>名人带留缓道</p></li>
                                                </ul>

                                            </div>

                                            <ul class="t_syk1btn clearfix">

                                                <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan1">

                                                        <a href="">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">查看详情</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li>

                                                <!-- <li class="t_syk1btnli fl">

                                                    <div class="t_btnan t_btnan2">

                                                        <a href="/appoint">

                                                            <div class="t_btnbox">

                                                                <div class="t_btnjia">

                                                                    <div class="t_btnzi">预约演示</div>

                                                                </div>

                                                            </div>

                                                        </a>

                                                    </div>

                                                </li> -->

                                            </ul>

                                        </div>

                                        <div class="t_syk1rg fr">

                                            <div class="t_syk1pic ys_imgbox_cover">

                                              <img src="../../assets/首页图片/sy-top2-4.jpg" alt="youtuget.png" style="    width: 600px;height: 400px;">


                                            </div>

                                        </div>

                                    </div>

                                </li>
                            

                            </ul>

                        </div>



          


              
           </div>


           
      </div>

      
     </div>
   </div>
  </div>


<!-- <div class="gudingdw">
  <img src="../../assets/img/QQlogo.png" alt="">
</div> -->

<div class="t_syk2">

                <div class="t_syk2nr">

                    <div class="t_syk2le">

                        <div class="t_syk2pic ys_imgbox_cover">

                            <!-- <img src="/Public/Cn/images/a2_imgh1.png" alt=""> -->

                            <img src="../../assets/img/img-1-04.jpg" style="width:100%;right:673px;height:570px" alt="a1_bg2h.jpg">

                        </div>

                        <div class="t_syk2letop">

                            <div class="t_syk2tu">

                                <!-- <img src="/Public/Cn/images/haitui.svg" alt="">  -->

                            </div>

                            <div class="t_duan18 wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">

                                <h2 class="t_fonth2">可信赖的<br>数字化合作伙伴</h2>

                                <p>产业互联网时代企业数字化升级的“新动能”</p>

                            </div>

                        </div>

                    </div>

                    <div class="t_syk2rg">

                        <ul class="t_syk2ul">

                            

                            <li class="t_syk2li">

                                    <!-- <a href="" target="_self"> -->

                                        <div class="t_syk2xin">

                                        <div class="t_syk2bg">

                                                  <img src="../../assets/img/sy-01.png" alt="a1_bg3.jpg" style="height:100%;">

                                            </div>

                                        
                                            

                                            <div class="t_syk2wen">

                                                <div class="t_syk2xu t_alsansbold wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">01</div>

                                                <div class="t_syk2xia wow ys_fadeup2" style="visibility: visible; animation-name: ysfadeInUp;">

                                                    <div class="t_font24">渠道有限，客户少</div>

                                                    <div class="t_duanp">

                                                        <p>获客难度大，流量不精准?</p>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    <!-- </a>                                 -->

                                </li><li class="t_syk2li">

                                    <!-- <a href="" target="_self"> -->

                                        <div class="t_syk2xin">

                                        
                                            <div class="t_syk2bg ys_imgbox_cover">

                                                <!-- <img src="/Public/Cn/images/a2_imgh2.png" alt=""> -->

                                                   <img src="../../assets/img/sy-02.png" alt="a1_bg3.jpg" style="height:100%;">

                                            </div>
                                            

                                            <div class="t_syk2wen">

                                                <div class="t_syk2xu t_alsansbold wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">02</div>

                                                <div class="t_syk2xia wow ys_fadeup2" style="visibility: visible; animation-name: ysfadeInUp;">

                                                    <div class="t_font24">企业转型 推广难</div>

                                                    <div class="t_duanp">

                                                        <p>传统行业不懂网络营销，效果难把控?</p>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    <!-- </a>                                 -->

                                </li><li class="t_syk2li">

                                    <!-- <a href="" target="_self"> -->

                                        <div class="t_syk2xin">

                                        <div class="t_syk2bg">

                                                   <img src="../../assets/img/sy-03.png" alt="a1_bg3.jpg" style="height:100%;">

                                            </div>

                                        
                                            

                                            <div class="t_syk2wen">

                                                <div class="t_syk2xu t_alsansbold wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">03</div>

                                                <div class="t_syk2xia wow ys_fadeup2" style="visibility: visible; animation-name: ysfadeInUp;">

                                                    <div class="t_font24">网络推广 效果差</div>

                                                    <div class="t_duanp">

                                                        <p>市场竞争激烈，销量难打开?</p>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    <!-- </a>                                 -->

                                </li><li class="t_syk2li">

                                    <!-- <a href="" target="_self"> -->

                                        <div class="t_syk2xin">

                                        
                                            <div class="t_syk2bg ys_imgbox_cover">

                                                <!-- <img src="/Public/Cn/images/a2_imgh2.png" alt=""> -->

                                                <img src="../../assets/img/sy-04.png" alt="a1_bg3.jpg" style="height:100%;">

                                            </div>
                                            

                                            <div class="t_syk2wen">

                                                <div class="t_syk2xu t_alsansbold wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">04</div>

                                                <div class="t_syk2xia wow ys_fadeup2" style="visibility: visible; animation-name: ysfadeInUp;">

                                                    <div class="t_font24">盲目投放 成本高</div>

                                                    <div class="t_duanp">

                                                        <p>担心费用不可控，怕花冤枉钱</p>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    <!-- </a>                                 -->

                                </li><li class="t_syk2li">

                                    <!-- <a href="" target="_self"> -->

                                        <div class="t_syk2xin">

                                        <div class="t_syk2bg">

                                                <img src="../../assets/img/sy-05.png" alt="" style="height: 100%;">

                                            </div>

                                        
                                            

                                            <div class="t_syk2wen">

                                                <div class="t_syk2xu t_alsansbold wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">05</div>

                                                <div class="t_syk2xia wow ys_fadeup2" style="visibility: visible; animation-name: ysfadeInUp;">

                                                    <div class="t_font24">力办公 效率低</div>

                                                    <div class="t_duanp">

                                                        <p>智能化办公时代一个软件等于一个部门</p>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    <!-- </a>                                 -->

                                </li><li class="t_syk2li">

                                    <!-- <a href="" target="_self"> -->

                                        <div class="t_syk2xin">

                                        
                                            <div class="t_syk2bg ys_imgbox_cover">

                                                <!-- <img src="/Public/Cn/images/a2_imgh2.png" alt=""> -->

                                                <img src="../../assets/img/sy-06.jpg" alt="a1_bg5.jpg" style="height: 100%;">

                                            </div>
                                            

                                            <div class="t_syk2wen">

                                                <div class="t_syk2xu t_alsansbold wow ys_fadeup1" style="visibility: visible; animation-name: ysfadeInUp;">06</div>

                                                <div class="t_syk2xia wow ys_fadeup2" style="visibility: visible; animation-name: ysfadeInUp;">

                                                    <div class="t_font24">同行业竞争激烈，太内卷</div>

                                                    <div class="t_duanp">

                                                        <p>不如跨境经营 利用优势开辟新市场</p>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    <!-- </a>                                 -->

                                </li>
                            

                        </ul>

                    </div>

                </div>

            </div>



    


  <!-- 内容2 -->

  
  <div class="hs_analyze3 p78 lpkStop">
	<div class="index_title wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
		<h5>中融科技云</h5>
	</div>
	<div class="box w1520">
		<div class="img">
			<img src="https:www.hengshi.com/template/default/pc/skin/images/five.png">
			<p><img src="https:www.hengshi.com/template/default/pc/skin/images/five_t.png"></p>
		</div>
		<div class="item flexEnd item1">
			<div class="left wow slideInUp animated" style="visibility: visible; animation-name: slideInUp;">
				<h5>多租户</h5>
				<div>
					<p>中心化管理指标体系</p>
					<p>租户单独计费</p>
					<p>信息数据隔离</p>
					<p>租户自助分析</p>
				</div>
			</div>
			<div class="right">
				<img src="https:www.hengshi.com/template/default/pc/skin/images/five1.png">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="108px" height="140px">

						<defs>
							<linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
								<stop offset="0" stop-color=" #a3c6ff"></stop>
								<stop offset="1" stop-color="rgb(31, 117, 254)"></stop>
							</linearGradient>
						</defs>

						<path fill-rule="evenodd" stroke="url(#linear)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M76.000,138.000 L1.000,97.000 L102.000,39.000 L41.000,3.000 "></path>

						<path fill-rule="evenodd" stroke="#95fbff" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" class="g-rect-fill" d="M76.000,138.000 L1.000,97.000 L102.000,39.000 L41.000,3.000 "></path>
					</svg>
				</p>
			</div>
		</div>
		<div class="item flexEnd item2">
			<div class="left wow slideInUp animated" style="visibility: visible; animation-name: slideInUp;">
				<h5>嵌入式</h5>
				<div>
					<p>标准化功能模块按需接入</p>
					<p>URL、iFrame、API</p>
					<p>嵌入应用、系统或网站</p>
					<p>支持多种 SSO 协议</p>
				</div>
			</div>
			<div class="right">
				<img src="https:www.hengshi.com/template/default/pc/skin/images/five2.png">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="296px" height="83px">
						<path fill-rule="evenodd" stroke="url(#linear)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M3.000,48.000 L36.000,68.000 L154.000,1.000 L294.000,79.000 "></path>
						<path fill-rule="evenodd" stroke="#95fbff" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" class="g-rect-fill" d="M3.000,48.000 L36.000,68.000 L154.000,1.000 L294.000,79.000 "></path>
					</svg>
				</p>
			</div>
		</div>
		<div class="item flexEnd item3">
			<div class="left wow slideInUp animated" style="visibility: visible; animation-name: slideInUp;">
				<h5>开放性</h5>
				<div>
					<p>云原生架构</p>
					<p>弹性扩容</p>
					<p>持续更新迭代</p>
					<p>MPP 架构列式储存</p>
				</div>
			</div>
			<div class="right">
				<img src="https:www.hengshi.com/template/default/pc/skin/images/five3.png">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="144px" height="134px">
						<defs>
							<linearGradient id="linear1" x1="0%" y1="0%" x2="100%" y2="0%">
								<stop offset="0" stop-color="rgb(31, 117, 254)"></stop>
								<stop offset="1" stop-color=" #a3c6ff"></stop>
							</linearGradient>
						</defs>
						<path fill-rule="evenodd" stroke="url(#linear1)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M135.000,3.000 L96.000,27.000 L142.000,54.000 L2.000,132.000 "></path>
						<path fill-rule="evenodd" stroke="#95fbff" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" class="g-rect-fill" d="M135.000,3.000 L96.000,27.000 L142.000,54.000 L2.000,132.000 "></path>
					</svg>
				</p>
			</div>
		</div>
		<div class="item flexStart item4">
			<div class="right">
				<img src="https:www.hengshi.com/template/default/pc/skin/images/five4.png">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="191px" height="138px">
						<path fill-rule="evenodd" stroke="url(#linear1)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M1.000,134.000 L121.000,64.000 L65.000,32.000 L124.000,1.000 L189.000,38.000 "></path>
						<path fill-rule="evenodd" stroke="#95fbff" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" class="g-rect-fill" d="M1.000,134.000 L121.000,64.000 L65.000,32.000 L124.000,1.000 L189.000,38.000 "></path>
					</svg>
				</p>
			</div>
			<div class="left wow slideInUp animated" style="visibility: visible; animation-name: slideInUp;">
				<h5>易用性</h5>
				<div>
					<p>拖拽式自助分析</p>
					<p>零代码数据建模</p>
					<p>函数、模型开箱即用</p>
					<p>业务指标、场景模板</p>
				</div>
			</div>
		</div>
		<div class="item flexStart item5">
			<div class="right">
				<img src="https:www.hengshi.com/template/default/pc/skin/images/five5.png">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="188px" height="70px">
						<path fill-rule="evenodd" stroke="url(#linear)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M2.000,1.000 L41.000,22.000 L76.000,2.000 L186.000,66.000 "></path>
						<path fill-rule="evenodd" stroke="#95fbff" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" class="g-rect-fill" d="M2.000,1.000 L41.000,22.000 L76.000,2.000 L186.000,66.000 "></path>
					</svg>
				</p>
			</div>
			<div class="left wow slideInUp animated" style="visibility: visible; animation-name: slideInUp;">
				<h5>敏捷性</h5>
				<div>
					<p>轻型数仓</p>
					<p>数据和模型分离</p>
					<p>数据和可视化实时打通</p>
					<p>多终端应用一键发布</p>
				</div>
			</div>
		</div>
	</div>
</div>






    <!-- 内容3 -->

    <div class="content3">
      <div class="content3-img">
        <div style="padding: 150px 30.75rem">
          <div style="color: #fff; font-size: 30px; font-weight: 600">
            开启您的数字化营销时代
          </div>
          <div class="content3-btn">立即开始推广</div>
        </div>
      </div>
    </div>
    <div class="content3-color"></div>


  </div>
</template>

<script>
import navTop from '@/components/nav/index.vue'
import APPbottom from '@/components/bottom/index.vue'

export default {
  components:{
navTop,
APPbottom 
  },
  data() {
    return {
      determine: 1,
      informationNum: 0,
      glide: 1,
      navNum: 0,

      isNavNum: false,
      header:require('../../assets/首页图片/sy-top-1.png'),
      informationsAll: [
        {
          name: "品牌建设",
          src:require("@/assets/img/品牌建设.png"),
          informations: [
            {
              text: "开屏首页",
              top: "开机页面曝光",
              content: "开屏第一入口强势曝光，沉浸式吸引用户关注",
              content2: "开屏第一视觉极致触达，创新互动，打造品牌营销事件场",
               src:require("../../assets/首页图片/品牌建设子栏_01.jpg"),
            },
            {
              text: "信息流",
              top: "信息流",
              content: "精准定向，多频触达，助力人群拉新，高效引流",
              content2: "可实现内容追投的品牌保量产品，助力人群破圈",
                     src:require("../../assets/首页图片/品牌建设子栏_02.jpg"),
            },
            {
              text: "类目种草",
              top: "类目种草",
              content: "帮助内容/创意加热实现品牌种草",
              content2:
                "高效获取品牌人群的认可。帮助提升品牌人群宣传效率，降低获取成本，适配多元营销场景，破解品牌种草难题",
                       src:require("../../assets/首页图片/品牌建设子栏_03.jpg"),
            },
            {
              text: "店铺推送",
              top: "店铺推送",
              content: "精准触达高潜购物用户，增加生意确定性的品牌广告",
              content2:
                "支持将您的店铺投放至各平台“猜你喜欢”位置，帮助品牌商家打造爆款商品，积累有购买意图的高潜用户",
                       src:require("../../assets/首页图片/品牌建设子栏_4.jpg"),
            },
          ],
        },
        {
          name: "搜索营销",
          src:require("@/assets/img/搜索营销.png"),
          informations: [
            {
              text: "搜索品牌广告",
              top: "搜索品牌广告",
              content: "品牌营销的专属名片",
              content2:
                "搜索结果页首位展现，通过话题、子链、平台账号商品卡等组件呈现品牌关键信息",
                       src:require("../../assets/首页图片/搜索营销子栏_01.jpg"),
            },
            {
              text: "搜索竟价广告",
              top: "信搜索竟价广告",
              content: "商家营销的转化利器",
              content2: "通过系统提供智能投放及优化能力，实现广告的自动化投放",
                       src:require("../../assets/首页图片/搜索营销子栏_02.jpg"),

            },
          ],
        },
        {
          name: "电商经营",
         src:require("@/assets/img/电商经营.png"),
          informations: [
            {
              text: "营销目标",
              top: "营销目标",
              content:
                "开屏第一入口强势曝光，沉浸式吸引用户关注开屏第一视觉极致触达，创新互动，打造品牌营销事件场",
              content2:
                "以商品为主要营销目标的投放。主要吸引用户直接关注商品，提升商品销量。促进下单。",
                       src:require("../../assets/首页图片/电商经验子栏_01.jpg"),

            },
            {
              text: "场景化投放",
              top: "场景化投放",
              content: "生意经营的关键场景",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/电商经验子栏_02.jpg"),

            },
          ],
        },

        {
          name: "本地经营",
          src:require("@/assets/img/本地经营.png"),
          informations: [
            {
              text: "短视频推商品",
              top: "短视频推荐商品",
              content: "推广视频帮助商品获取更高转化",
              content2:
                "以商品为主要营销目标的投放。主要吸引用户直接关注商品，提升商品销量。促进下单。",
                       src:require("../../assets/首页图片/本地经营子栏_01.jpg"),

            },
            {
              text: "短视频推门店",
              top: "短视频推荐门店",
              content: "推广视频帮助门店涨人气",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/本地经营子栏_02.jpg"),

            },
            {
              text: "直播推商品",
              top: "直播推荐商品",
              content: "推广直播帮助商品获取更高转化",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/本地经营子栏_03.jpg"),

            },
          ],
        },

        {
          name: "内容下载",
          src:require("@/assets/img/内容下载.png"),
          informations: [
            {
              text: "APP增量",
              top: "营销目标",
              content: "对于APP机始用户增长胎段有极大帮助",
              content2:
                "用户增长一直是酱大APP的首要网题，持债的增长，用户的增加。侵进APP不断完善",
                       src:require("../../assets/首页图片/内容下载子栏_01.jpg"),

            },
            {
              text: "福利领取",
              top: "福利领取",
              content: "生意经营的关键场景",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/内容下载子栏_02.jpg"),

            },
            {
              text: "内容供给实检室",
              top: "内容供给实检室",
              content: "生意经营的关键场景",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/内容下载子栏_03.jpg"),

            },
          ],
        },
        {
          name: "留资获客",
          src:require("@/assets/img/留资获客.png"),
          informations: [
            {
              text: "营销目标",
              top: "营销目标",
              content:
                "开屏第一入口强势曝光，沉浸式吸引用户关注开屏第一视觉极致触达，创新互动，打造品牌营销事件场",
              content2:
                "以商品为主要营销目标的投放。主要吸引用户直接关注商品，提升商品销量。促进下单。",
                       src:require("../../assets/首页图片/留资获客子栏_01.jpg"),

            },
            {
              text: "场景化投放",
              top: "场景化投放",
              content: "生意经营的关键场景",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/留资获客子栏_01.jpg"),

            },
          ],
        },
        {
          name: "自主营销",
         src:require("@/assets/img/自主营销.png"),
          informations: [
            {
              text: "自主投放",
              top: "自主投放",
              content:
                "开屏第一入口强势曝光，沉浸式吸引用户关注开屏第一视觉极致触达，创新互动，打造品牌营销事件场",
              content2:
                "以商品为主要营销目标的投放。主要吸引用户直接关注商品，提升商品销量。促进下单。",
                       src:require("../../assets/首页图片/自主投放子栏_01.jpg"),

            },
            {
              text: "共同成长伙伴",
              top: "共同成长伙伴",
              content: "生意经营的关键场景",
              content2:
                "根据不同用户的购物习惯，不同场景下优化视频或图文提升日常的商品销量与直播间转化",
                       src:require("../../assets/首页图片/自主投放子栏_02.jpg"),

            },
          ],
        },
      ],
      navs: [
        {
          name: "产品中心",
          navContent: [
            { text: "短信通知",path:'SmsNotification' },
            { text: "抖音推广" ,path:'TiktokPromotion'},
            { text: "短信验证码",path:'SmsVerification' },
            { text: "朋友圈推广" },
            { text: "短信营销",path:'SmsMarketing'},
            { text: "小程序定制" },
            { text: "视频彩信",path:'VideoMms'},
            { text: "企业网站设计" },
            { text: "霸屏闪信" },
            { text: "政企合作" },
          ],
        },
        {
          name: "工具中心",
          navContent: [
            { text: "视频去水印" },
            { text: "WORD转PDF" },
            { text: "图片变清晰" },
            { text: "图片设计" },
            { text: "视频剪辑" },
          ],
        },
         {
          name: "新闻资讯",
          navContent: [{ text: "公司新闻" }, { text: "行业动态" }],
        },
        { name: "联系我们", navContent: [{ text: "在线咨询" }] },
         { name: "关于我们", navContent: [{ text: "企业简介" }] },

      ],
    };
  },

  methods: {
    handleEnterNavs(index) {
      this.navNum = index;
      this.isNavNum = true;
    },
    handleleaveNavs() {
      this.isNavNum = false;
    },
    onGlide(index) {
      this.glide = index;
      this.informationNum = 0;
    },

    handleEnter(e) {
      this.determine = e;
      console.log("this.determine", this.determine);
    },
    handleleave() {
      this.determine = 1;
      console.log("handleleave");
    },
    onChanges(e) {
      this.informationNum = e;
      console.log("informationNum");
    },
    ontiaozhuan() {
      this.$router.push({ name: "Logisticsplan" });
    },
    //跳转产品中心
    onProductCenter(e){
       this.$router.push({ name: e,params:{id:'测试'}});
      console.log('跳转产品中心',e);
    }
  },
};
</script>

<style scoped>

.gudingdw{
  position: fixed;
  top: 1000px;
  right: 200px;
  z-index: 1000;
}

* {
  margin: 0;
  padding: 0;
}
.top-dinwei {
  position: absolute;
  top: 300px;
  left: 500px;
}

.flex{
  display: flex;
}
.content-flex{
  display: flex;
  justify-content: center;

}
ul {
  list-style: none; /* 去除无序列表的默认符号 */
}

.nav {
  display: flex;
  overflow: hidden;
  padding-left: 30px;
}
.nav-item {
  margin-left: 40px;
  margin-top: 30px;
  font-size: 1.6875rem;
  font-weight: 700;
  /* width: 100px; */
}

.glide {
  border-bottom: 2px solid rgb(32, 86, 227);
}
.determine {
  width: 400px !important;
  display: block;
}
.determineNo {
  width: 20% !important;
  display: none;
}

.top {
  width: 100%;
  height: 700px;
  /* background: url("../../assets/img"); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  /* padding-top: 200px; */
  /* padding-left: 500px; */
  /* display: flex; */
}
.hide {
  display: block !important;
}
.top-hide {
  /* margin-top: 20px; */

  /* margin-left: 40px; */
  /* position: absolute;
  top: 90px;
  width: 95%; */
  margin-top: 20px;
  width: 95%;
  height: 0px;
  background: #fff;
  overflow: hidden;
  color: black;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  animation: showAnimation 1s ease forwards;
    
}

.top-hide-all{
    display: flex;
    justify-content: center;
 
}

    @keyframes showAnimation{
   100%{height:100px;}}
 /* @keyframes showAnimation {
            0% { opacity: 0; transform: translateY(-20%); } 
  100% { opacity: 1; transform: translateY(0); } 
        } */


.top-hide-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.top-hide-text {
  font-size: 1.5rem;
  margin-right: 30px;
  cursor: pointer;
}
.top-text {
  font-size: 60px;
  font-weight: 600;
}

.top-text2 {
  font-size: 30px;
  margin-top: 10px;
  color: rgb(96, 96, 100);
}

/* content内容1 */
.conter{
 display: flex;
 justify-content: center;
  /* background-color: rgb(205, 217, 225); */
  background: url('../../assets/img/首页_02.jpg');
    background-size: cover;
  background-repeat: no-repeat;
}

.content {
  /* background-color: rgb(205, 217, 225); */
  /* padding: 100px 30.5rem 0px 30.5rem; */
  margin-top: 2px;
  /* width: 50%; */
}
.content-flex-width{
   margin-top: 2px;
  width: 50%;
}
.content-bottom-width{
  width: 60%;

}
@media screen and (max-width: 2000px) {
.content-flex-width {
  
  margin-top: 2px;
  width: 60%;
}
.content-bottom-width{
  width: 70%;

}
}

.content-top {
  display: flex;
  justify-content: center;
}

.content-top-text1 {
  font-size: 40px;
  font-weight: 700;
  color: black;
  margin-top: 100px;
}
.content-top-text1-1 {
  font-size: 40px;
  font-weight: 700;
  color: black;
  margin-top: 20px;
}

.content-top-text2 {
  margin-top: 10px;
  color: rgb(158, 154, 155);
}

.content-classification {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 60px;
}

.content-classification-item {
  text-align: center;
  padding: 10px 15px;
  cursor: pointer;
}

.content-classification-item-text {
  color: rgb(32, 86, 227);
  font-size: 25px;
}

.content-classification-bianhua {
  width: 100%;

  margin-top: 80px;

  border-radius: 30px;
  /* overflow: hidden; */
  height: 32rem;
  display: flex;
}

.content-classification-bianhua-left {
  /* width: 15%; */

}

.content-classification-bianhua-right {
  width: 75%;
  background-color: #fff;
  padding-top: 50px;
  padding-left: 70px;
  position: relative;
  border-radius: 0 30px 30px 0;

}
.content-classification-bianhua-right-img{
  position: absolute;
  top: -20px;
  right: -100px;
}

/* .content-classification-bianhua-right-flex{
  display: flex;
} */

.content-classification-bianhua-left-item {
  height: 8rem;
  width: 100%;
  line-height:8rem;
  text-align: center;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  background-color: #fff;
  opacity: 0.7;
}

.on-selected {
  background-color: rgb(32, 86, 227);
  opacity: 1;
  color: #fff;
  font-weight: 600;
}

.content-classification-bianhua-right-text-top {
  font-size: 45px;
}

.content-classification-bianhua-right-text {
  font-size: 20px;
  margin-top: 5px;
  color: rgb(115, 115, 115);
}

.content-classification-bianhua-right-btn {
  margin-top: 200px;
  width: 150px;
  height: 45px;
  line-height: 45px;
  background-color: rgb(32, 86, 227);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.content-bottom {
  margin-top: 50px;

  text-align: center;
}

.content-bottom-content {
  margin-top: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-bottom-content-item {
  /* width: 19%; */
  width: 350px;
  height: 500px;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  overflow: hidden;
  margin-left: 2px;
  transition: 0.5s;
}
.text-huadong{
  position: relative; /* 设置相对定位 */
  width: 220px;
  margin-left: 30px;
  margin-top: 10px;
}
.text-huadong::before {
  content: ""; /* 必须有内容才能显示伪元素 */
  display: block; /* 将伪元素转换成块级元素 */
  width: 6px; /* 设置小点的宽度 */
  height: 6px; /* 设置小点的高度 */
  background-color: black; /* 设置小点的背景色 */
  border-radius: 50%; /* 设置小点为圆形 */
  position: absolute; /* 设置绝对定位 */
  top: 10px; /* 调整小点在盒子上边界的位置 */
  left: -15px; /* 调整小点在盒子左边界的位置 */
}

.content-bottom-content-item1 {
  width: 350px;
  height: 500px;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  overflow: hidden;
  margin-left: 2px;
  transition: 0.5s;
}

.content-bottom-content-item-img-dis {
  padding: 20px;

  /* display: none; */
}

/* .content-bottom-content-item:hover {
        width: 350px;
    } */

.content-bottom-content-item:hover .content-bottom-content-item-img {
  display: none;
}

.content-bottom-content-item:hover .content-bottom-content-item-img2 {
  display: none;
}

.content-bottom-content-item:hover .content-bottom-content-item-img3 {
  display: none;
}

.content-bottom-content-item:hover .content-bottom-content-item-img4 {
  display: none;
}

.content-bottom-content-item:hover .content-bottom-content-item-img5 {
  display: none;
}

.content-bottom-content-item:hover .content-bottom-content-item-img-dis {
  display: none;
}

.content-bottom-content-item:hover .content-bottom-content-item-img-dis {
  display: block;
}

.content-bottom-content-item:hover .content-bottom-content-item-img-dis {
  display: block;
}

.content-bottom-content-item:hover .content-bottom-content-item-img-dis {
  display: block;
}

.content-bottom-content-item:hover .content-bottom-content-item-img-dis {
  display: block;
}

.content-bottom-content-item-img {
  background: url("../../assets/img/智能化技术.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 650px;
  /* display: none; */
}

.content-bottom-content-item-img2 {
  background: url("../../assets/img/用户信息安全.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 650px;
}

.content-bottom-content-item-img3 {
  background: url("../../assets/img/庞大的配套应用.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 650px;
}

.content-bottom-content-item-img4 {
  background: url("../../assets/img/全网皆可触达.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 650px;
}

.content-bottom-content-item-img5 {
  background: url("../../assets/img/大数据支持.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 650px;
}

.content-bottom-content-item-text {
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  border-radius: 10px;
}

.content-bottom-content-item-text2 {
  margin-top: 20px;
  letter-spacing: 2px;
  line-height: 1.5;
  color: rgb(115, 115, 115);
}

/* 内容2 */
.content2 {
  margin-top: -10px;

  background-color: rgb(230, 240, 255);
  padding: 80px 34.375rem 100px 34.375rem;
}

.content2-top {
  text-align: center;
  display: flex;
  justify-content: center;
}

/* 内容3 */
.content3 {
  border-top: 2px solid rgb(32, 86, 227);
}

.content3-img {
  width: 100%;
  background: url("../../assets/img/logobanner.jpg");
  background-repeat: no-repeat;
  /* background-size:100% 400px; */
  background-size: cover;
  height: 400px;
}

.content3-btn {
  margin-top: 50px;
  width: 150px;
  height: 45px;
  line-height: 45px;
  background-color: rgb(32, 86, 227);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.content3-color {
  height: 50px;
  background-color: rgb(93, 93, 93);
  border-bottom: 2px solid rgb(32, 86, 227);
}

.bottom {
  padding: 50px 20rem;
  background-color: black;

  color: #fff;
}

.bottom-top {
  display: flex;
  justify-content: space-between;
}

.bottom-left {
  width: 70%;

  display: flex;
  justify-content: space-between;
}

.bottom-right {
  width: 25%;
  margin-top: 30px;
}

.bottom-left-item1 {
  /* width: 15%; */
}

.bottom-left-item1-top {
  border-bottom: 1px solid rgb(93, 93, 93);
  padding: 10px 20px 15px 0px;
  font-size: 17px;
}

.bottom-left-item1-item {
  margin-top: 20px;
  font-size: 16px;
  color: rgb(158, 154, 155);
  cursor: pointer;
}

.bottom-right-top {
  display: flex;
  align-items: center;
}

.bottom-right-top-right {
  margin-left: 10px;
}

.bottom-right-bottom {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.bottom-right-right-icon {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.bottom-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  color: rgb(158, 154, 155);
}

.cs {
  text-align: left;
}
.home-conter{
 width: 70%;
 /* border: 1px solid red; */
}
.home{
  padding: 100px 0;
}
.home-conter-text1{
   font-size: 40px;
   font-weight: 700;
   color: #333;
   text-align: center;
   padding: 0 0 10px;
}
.home-conter-text2{
 font-size: 18px;
   text-align: center;
padding-bottom: 50px;
}
.home-conter-content{
 /* width: 80%; */
}
.home-conter-content-top{
 display: flex;
 justify-content: space-between;
 align-content: center;

     background: #fff;
    box-shadow: 0px 2px 19px 10px rgba(22, 44, 112, 0.05);
    border-radius: 8px;
    position: relative;
    z-index: 2;
    overflow: hidden;
  
}
.home-conter-content-top-item{
    cursor: pointer;
    position: relative;
    padding: 30px 30px 30px 40px;
    transition: all 0.36s;
    width: 25%;
}
.home-conter-content-top-item .t_fonth5 {
    height: 30px;
    overflow: hidden;
     font-weight: bold;
    transition: all 0.1s;
    margin-bottom: 1px;
    font-size: 20px;
}
.home-conter-content-top-item .t_duanp {
    height: 40px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}
.home-conter-content-top-item::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background: #F0F0F0;
}
.home-conter-content-top-item:hover{
  background: #3768FF;
  color: #fff;
}
.t_syk1qieli.on .t_duanp p {
    color: #fff;
}


.t_syk1huli{
    display: none;
}
.t_syk1huli.act{
    display: block;
}
.t_syk1huan{
    position: relative;
    z-index: 2;
}
.ys_fadeup4 {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: .4s;
}
.wow {
    visibility: hidden;
}
.t_syk1huli {
    display: none;
}
.t_syk1huli {
    padding: 0 30px;
}
.clearfix {
    zoom: 1;
}
.t_syk1le {
    width: 50.2%;
    padding-right: 50px;
    padding-top: 25px;
}
.fl {
    float: left;
}
.t_syk1huli.act .t_syk1le .t_fonth4 {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.3s;
}

@keyframes ysfadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


.t_syk1le .t_fonth4 {
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
}
.t_fonth4, .yxedr_active h4 {
    font-size: 30px;
    color: #333;
    line-height: 1.5;
}
.t_syk1huli.act .t_syk1le .t_duanp {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.4s;
}
.t_syk1le .t_duanp {
    margin-bottom: 36px;
}
.t_syk1lie {
    height: 124px;
    overflow: hidden;
}
.t_syk1lie {
    margin-bottom: 54px;
}
.t_syk1lie.yxedr_active ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 30px;
}
.yxedr_active ol, .yxedr_active ul {
    padding-inline-start: 30px;
}
.yxedr_active ol, .yxedr_active ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
}
.t_syk1lie.yxedr_active ul li::marker {
    display: none;
    opacity: 0;
    color: transparent;
}
.t_syk1lie.yxedr_active ul li::before {
    content: url('../../assets/img/对勾小.png');
    width: 18px;
    position: absolute;
    left: -30px;
    top: 5px;
}
.t_syk1huli.act .t_syk1lie.yxedr_active ul li {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.5s;
}
.t_syk1lie.yxedr_active ul li {
    padding-right: 30px;
}
.t_syk1lie.yxedr_active ul li {
    position: relative;
    width: 33.333333%;
    float: left;
}
.yxedr_active ol li, .yxedr_active ul li {
    list-style: inherit;
}
.t_syk1lie li p {
    height: 28px;
    overflow: hidden;
}
.t_syk1lie li p {
    font-weight: bold;
    color: #333;
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 22px;
}
.t_syk1huli.act .t_syk1btn {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.5s;
}
.t_syk1btnli {
    padding: 0 12px;
}
.t_btnan {
    display: inline-block;
}
.t_btnan {
    overflow: hidden;
    border-radius: 28px;
}
a, button {
    cursor: pointer;
}
.t_btnbox {
    position: relative;
    text-align: center;
    width: 200px;
    border-radius: 28px;
    transition: all 0.36s;
    border: 1px solid transparent;
    cursor: pointer;
}
.t_btnbox::before {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    background: linear-gradient(90deg, #00C9FF 0%, #3768FF 100%);
    border-radius: 28px;
    top: -1px;
    left: -1px;
    transition: all 0.36s;
}
.t_btnjia {
    position: relative;
}
.t_syk1btnli .t_btnzi {
    line-height: 58px;
}
.t_btnan1 .t_btnzi {
    color: #fff;
}
.t_btnzi {
    border-radius: 27px;
}

element.style {
}
.t_syk1btnli .t_btnzi {
    line-height: 58px;
}
.t_btnan1 .t_btnzi {
    color: #fff;
}
.t_btnzi {
    border-radius: 27px;
}
.t_btnzi {
    font-size: 16px;
    transition: all 0.36s;
    line-height: 54px;
    position: relative;
    z-index: 4;
    transition: all 0.36s;
    color: #3768FF;
}
.t_btnbox::after {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    background: #3768FF;
    border-radius: 28px;
    top: -1px;
    right: -100%;
    transition: all 0.36s;
    opacity: 0;
    z-index: 3;
}
@media screen and (min-width: 1200px){
.t_btnan.t_btnan2 .t_btnbox:hover .t_btnzi {
    color: #fff;
}
}
@media screen and (min-width: 1200px){
.t_btnan .t_btnbox:hover .t_btnzi {
    color: #fff;
}
}
.t_btnan2 .t_btnbox {
    overflow: visible;
}
.t_haobtnli:first-child .t_btnan2 .t_btnjia::before{
    background: transparent;
    border: 1px solid #fff;
}
.t_btnan2 .t_btnjia::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 27px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0; 
    z-index: 2;
    transition: all 0.36s;
}
.t_btnan2 .t_btnzi{
    color: #3768FF;
}


/*鼠标效果*/
@media screen and (min-width: 1200px) {
   .t_btnan .t_btnbox:hover::after{
       right: -1px;
       opacity: 1;
   }
   .t_headoneli:hover .t_headonenav::before {
        width: 100%;
        opacity: 1;
    }
    .t_btnan.t_btnan2 .t_btnbox:hover .t_btnzi{
        color: #fff;
    }
    .ys_hd_pc.isfixed .t_headoneli:hover .t_headonenav a {
        color: #3768FF;
    }
    .t_syk1qieli:hover{
        background: #3768FF;
    }
    .t_syk1qieli:hover .t_syk1jiao.ys_imgbox_cover img:first-child{
        opacity: 1;
    }
    .t_syk1qieli:hover .t_syk1jiao.ys_imgbox_cover img:nth-of-type(2){
        opacity: 0;
    }
    .t_syk1qieli:hover .t_duanp p{
        color: #fff;
    }
    .t_syk1qieli:hover .t_fonth5{
        color: #fff;
    }
    /* .t_syk2li .t_syk2xin:hover .t_syk2bg{
        border-radius: 8px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
    } */
    /* .t_syk2li .t_syk2xin:hover{
        z-index: 3;
    } */
    .t_syk2li .t_syk2xin:hover .t_syk2xia{
        bottom: 0;
    }
    .t_syk2li .t_syk2xin:hover .t_syk2xia .t_duanp{
        opacity: 1;
    }
    .t_btnan .t_btnbox:hover .t_btnzi{
        color: #fff;
    }
    .t_syk3bo a:hover .t_anbtnale img:first-child{
        opacity: 0;
    }
    .t_syk3bo a:hover .t_anbtnale img:nth-of-type(2){
        opacity: 1;
    }
    .t_g3k1li a:hover .t_anbtnale img:first-child{
        opacity: 0;
    }
    .t_g3k1li a:hover .t_anbtnale img:nth-of-type(2){
        opacity: 1;
    }
    .t_g3k1li a:hover .t_g3k1pic img{
        transform: scale(1.05);
    }
    .t_syk3bo a:hover .t_syk3pic img{
        transform: scale(1.05);
    }
    .t_lunbo1 .slick-prev:hover,.t_lunbo1 .slick-next:hover,
    .t_lunbo2 .slick-prev:hover,.t_lunbo2 .slick-next:hover{
        background: linear-gradient(90deg, #00C9FF 0%, #3768FF 100%);
    }
    .t_lunbo1 .slick-prev:hover::after,
    .t_lunbo2 .slick-prev:hover::after{
        opacity: 1;
    }
    .t_lunbo1 .slick-next:hover::after,
    .t_lunbo2 .slick-next:hover::after{
        opacity: 1;
    }
    .t_lunbo1 .slick-prev:hover::before,
    .t_lunbo2 .slick-prev:hover::before{
        opacity: 0;
    }
    .t_lunbo1 .slick-next:hover::before,
    .t_lunbo2 .slick-next:hover::before{
        opacity: 0;
    }
    .t_syk4rgmore a:hover .t_syk4more::before{
        left: 0;
    }
    .t_syk5bo a:hover .t_syk5pic img{
        transform: scale(1.05);
    }
    .t_syk6 .t_syk6nr:hover .t_syk6pic img{
        transform: scale(1.05);
    }
    .t_haobtnli:first-child .t_btnan2 .t_btnbox:hover .t_btnjia::before{
        border: 1px solid #3768FF;
    }
    .t_dixiali a:hover{
        opacity: 1;
    }
    .t_dinaverli a:hover{
        opacity: 1;
    }
    .t_headsanli a:hover{
        color: #3768FF;
    }
    .t_headernav a:hover{
        color: #3768FF;
    }
    .t_ceboxli:hover .t_ceboxup{
        background: #fff;
    }
    .t_ceboxli:hover .t_cezi{
        color: #3768FF;
    }
    .t_ceboxli:hover .t_ceboxup .ys_imgbox_cover img:nth-child(2) {
        opacity: 1;
    }
    .t_ceboxli:hover .t_ceboxup .ys_imgbox_cover img:first-child {
        opacity: 0;
    }
    .t_neimainli a:hover{
        opacity: 1;
    }
    .t_d1k2li a:hover .t_d1k2pic img{
        transform: scale(1.05);
    }
    .pc_fenye li:hover {
        background: #3768FF;
        color: #fff;
    }
    .t_e1k1li a:hover .t_e1k1qu{
        opacity: 1;
    }
    .t_e1k1li a:hover .e1k1pic img{
        transform: scale(1.05);
    }
    .t_e1k1li a:hover{
        background: #F5F5F5;
    }
    .t_e2zhwen a:hover{
        color: #3768FF;
    }
    .t_e2rgli a:hover .t_e2rgxin::before{
        opacity: 1;
    }
    .t_e3k1li a:hover .t_e3k1pic img{
        transform: scale(1.05);
    }
    .t_e5k1li1 a:hover .t_e5k1pic>img{
        transform: scale(1.05);
    }
    .t_e6k2li a:hover .t_e5k1pic>img{
        transform: scale(1.05);
    }
    .t_select1 .ys_select_li:hover{
        color: #0055FF;
    }
    .t_g3k1li a:hover .t_g3k1xin::after{
        width: 100%;
        opacity: 1;
    }
    .t_nei3btn .t_btnan.t_btnan2 .t_btnbox:hover .t_btnzi {
        color: #fff;
    }
    .t_b1k2xin:hover .t_b1k2ic img:nth-of-type(2){
        opacity: 1;
    }
    .t_b1k2xin:hover .t_b1k2ic img:first-child{
        opacity: 0;
    }
    .t_b1k2xin:hover{
        background: #3768FF;
    }
    .t_b1k2xin:hover .t_fonth5{
        color: #fff;
    }
    .t_b1k2xin:hover .t_duanp p{
        color: #fff;
    }
    .t_b1k3xin:hover .t_b1k3ic img:nth-of-type(2){
        opacity: 1;
    }
    .t_b1k3xin:hover .t_b1k3ic img:first-child{
        opacity: 0;
    }
    .t_b1k3xin:hover{
        background: #3768FF;
    }
    .t_b1k3xin:hover .t_fonth5{
        color: #fff;
    }
    .t_b1k3xin:hover .t_duanp p{
        color: #fff;
    }
    .t_b1k1xin:hover .t_b1k3up{
        opacity: 1;
        z-index: 3;
    }
    .t_b1k1xin:hover .t_b1k1top{
        opacity: 0;
    }
    .t_b2k3xin:hover .t_b2k3pic img{
        transform: scale(1.05);
    }
    .t_b4k1upli:hover .t_font22::before{
        width: 100%;
        opacity: 1;
    }
    .t_b4k1upli:hover .t_font22{
        color: #333;
    }
    .t_btnan.t_btnan2.t_btnan4 .t_btnbox:hover .t_btnzi {
        color: #fff;
    }
    .t_f3btn:hover .t_f3btnrg img:nth-of-type(2){
        opacity: 1;
    }
    .t_f3btn:hover .t_f3btnrg img:first-child{
        opacity: 0;
    }
    .t_f3k1qieli:hover .deco:before {
        width: 42px;
        height: 42px;
    }
    .t_f3k1qieli:hover .deco:after {
        width: 68px;
        height: 68px;
    }
    .t_f3k1qieli:hover .deco i {
        width: 18px;
        height: 18px;
    }
    .t_f3k4xin:hover::before{
        opacity: 1;
    }
    .t_g2k4xi:hover .t_duanp{
        top: 0;
    }
    .t_g2k4xi:hover .t_g2k4wen{
        background: #3768FF;
    }
    .t_b3btn a:hover::before{
        width:calc(100% + 2px);
    }
    .t_c1k1xin:hover{
        background:#3768FF ;
    }
    .t_c1k1xin:hover .t_c1k1rg .t_fonth5,
    .t_c1k1xin:hover .t_c1k1rg .t_duanp p{
        color: #fff;
    }
    .t_c1k1xin:hover .t_c1k1ic img:nth-of-type(2){
        opacity: 1;
    }
    .t_c1k1xin:hover .t_c1k1ic img:first-child{
        opacity: 0;
    }
    .t_f1k4btnxi:hover::before{
        width: calc(100% + 2px);
    }
    .t_diyouul a:hover{
        color: #fff;
    }
}   
.home-conter-content-top-item.on {
    background: #3768FF !important;
    color: #fff;
}

.t_syk1huli.act .t_syk1le .t_fonth4{
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.3s;
}
.t_syk1huli.act .t_syk1le .t_duanp{
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.4s;
}
.t_syk1huli.act .t_syk1lie.yxedr_active ul li{
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.5s;
}
.t_syk1huli.act .t_syk1btn{
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.5s;
}
.t_syk1huli.act .t_syk1rg{
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: 0.3s;
}



.t_syk2le{
    width: 40.625%;
    background: #3768FF;
    position: relative;
}
.t_syk2le .t_syk2pic{
    position: relative;
    opacity: .5;
}
.t_syk2pic::before,.t_syk2pic::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.36s;
    z-index: 3;
}
.t_syk2pic::before{
    background: rgba(0,0,0,.5);
}
.t_syk2pic::after{
    background: rgba(22, 44, 112, 0.3);
}
.t_syk2letop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}
.t_syk2tu{
    width: 62%;
    position: absolute;
    left: 0;
    top: 15.6%;
}
.t_syk2tu img{
    width: 100%;
}
.t_syk2letop .t_duan18 p{
    color: #fff;
}
.t_syk2letop .t_duan18 {
    width: 56.5%;
    position: absolute;
    left: 33.6%;
    top: 42.5%;
}
.t_syk2nr{
    align-items: stretch;
    display: flex;
}
.t_syk2rg{
    width: 59.375%;
}
.t_syk2ul {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.t_syk2li{
    width: 33.333333%;
    height: 50%;
}
.t_syk2xin{
    position: relative;
    height: 100%;
}
.t_syk2bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #3768FF;
    transition: all 0.36s;
}
.t_syk2xu{
    font-size: 24px;
    color: #fff;
    line-height: 1.5;
}
.t_syk2wen{
    position: relative;
    z-index: 2;
}
.t_syk2xia .t_font24{
    font-weight: bold;
    color: #fff;
    margin-bottom: 24px;
}
.t_syk2xia .t_duanp p{
    color: #fff;
}
.t_syk2wen{
      padding:10px 28px;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.t_syk2xia{
    position: absolute;
    bottom: -85px;
    padding-bottom: 30px;
    left: 40px;
    width: calc(100% - 80px);
    transition: all 0.36s;
}
.t_syk2li:nth-of-type(2n) .t_syk2bg{
    background: transparent;
}
.t_syk2li:nth-of-type(2n)  .t_syk2bg::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(30,56,166,.5);
    z-index: 3;
}
.t_syk2li:nth-of-type(5) .t_syk2bg{
    background: linear-gradient(90deg, #00C9FF 0%, #3768FF 100%);
}
.t_syk2li a{
    display: block;
    height: 100%;
}
.t_syk2li .ys_imgbox_cover img:nth-child(2){
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.t_syk2xia .t_duanp{
    opacity: 0;
    transition: all 0.36s;
    height:64px;
    overflow: hidden;
}
.t_font24 {
    font-size: 24px;
    line-height: 1.5;
}
.t_syk2xia .t_duanp {
    -webkit-line-clamp: 3;
    height: 96px;
}
.t_syk2pic::before, .t_syk2pic::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.36s;
    z-index: 3;
}
.t_syk2pic::before {
    background: rgba(0,0,0,.5);
}
.t_syk2pic::before, .t_syk2pic::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.36s;
    z-index: 3;
}
.t_syk2pic::after {
    background: rgba(22, 44, 112, 0.3);
}
.t_syk2tu {
    width: 62%;
    position: absolute;
    left: 0;
    top: 15.6%;
}
.t_syk2letop .t_duan18 {
    width: 56.5%;
    position: absolute;
    left: 33.6%;
    top: 42.5%;
}
.ys_fadeup1 {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: .1s;
}
.t_syk2letop .t_fonth2 {
    color: #fff;
    margin-bottom: 30px;
}
.t_syk2letop .t_duan18 p {
    color: #fff;
}
.t_duan18 p {
    font-size: 18px;
    line-height: 2;
    color: #666;
}
.t_syk2ul {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.t_syk2li {
    width: 33.333333%;
    height: 50%;
}
.t_syk2xin {
    position: relative;
    height: 100%;
}
.t_syk2bg {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
}
.t_syk2bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #3768FF;
    transition: all 0.36s;
}
.t_syk2xu {
    font-size: 24px;
    color: #fff;
    line-height: 1.5;
}
.t_alsansbold {
    font-family: 'alibaba_sansbold'!important;
}
.ys_fadeup1 {
    animation: ysfadeInUp .5s ease-in-out both;
    animation-delay: .1s;
}
.t_syk2xia .t_font24 {
    font-weight: bold;
    color: #fff;
    margin-bottom: 24px;
}
.t_syk2xia .t_duanp {
    -webkit-line-clamp: 3;
    height: 96px;
}
.t_syk2xia .t_duanp {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}
.t_syk2xia .t_duanp {
    opacity: 0;
    transition: all 0.36s;
    height: 64px;
    overflow: hidden;
}




.hs_analyze3 {
	background: url(https:www.hengshi.com/template/default/pc/skin/images/fivebg.png) no-repeat 50% 50%;
	background-size: 100% 100%;
	/* margin-top: 5rem; */
	padding-bottom: 11.25rem;
}

.hs_analyze3 .box {
	margin-top: 12.125rem;
	position: relative;
}

.hs_analyze3 .box .img {
	width: 386px;
	margin: auto;
	position: relative;
	z-index: 3;
}

.hs_analyze3 .box .img>img {
	width: 100%;
}

.hs_analyze3 .box .img p {
	position: absolute;
	top: 11%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	perspective: 800px;
}

.hs_analyze3 .box .img p img {
	width: 126px;
	transform-style: preserve-3d;
	animation: rota1 3s infinite linear;
}

@keyframes rota1 {
	0% {
		transform: rotateY(0deg);
	}

	50% {
		transform: rotateY(-30deg);
	}

	100% {
		transform: rotateY(0deg);
	}
}

.hs_analyze3 .box .item {
	position: absolute;
	width: 320px;
}

.hs_analyze3 .box .item .left {
	text-align: right;
}

.hs_analyze3 .box .item .left h5 {
	font-size: 1.5rem;
	color: #000;
	font-weight: bold;
	margin-bottom: 16px;
}

.hs_analyze3 .box .item .left div p {
	font-size: 16px;
	color: #666;
	line-height: 32px;
	cursor: default;
	transition: 0.3s;
}

.hs_analyze3 .box .item .left div p:hover {
	color: #1f75fe;
}

.hs_analyze3 .box .item .right {
	padding-top: 10px;
	margin-left: 40px;
	position: relative;
}

.hs_analyze3 .box .item .right img {
	width: 100px;
	position: relative;
	z-index: 2;
	transition: 0.4s;
}

.hs_analyze3 .box .item .right p {
	position: absolute;
}

.hs_analyze3 .box .item .right:hover img {
	transform: scale(1.05);
}

.hs_analyze3 .box .item1 {
	top: -140px;
	left: 390px;
}

.hs_analyze3 .box .item1 .right p {
	top: 106px;
	left: 25px;
}

.hs_analyze3 .box .item1 .right .g-rect-fill {
	fill: none;
	stroke-dasharray: 0, 300;
	stroke-dashoffset: 0;
	animation: xian1 2s linear 0.3s infinite;
}

.hs_analyze3 .box .item2 {
	top: 45px;
	left: 88px;
}

.hs_analyze3 .box .item2 .right p {
	top: 60px;
	left: 64px;
}

.hs_analyze3 .box .item2 .right .g-rect-fill {
	fill: none;
	stroke-dasharray: 100, 350;
	stroke-dashoffset: 0;
	animation: xian2 2.5s linear 0.9s infinite;
}

.hs_analyze3 .box .item3 {
	top: 350px;
	left: 182px;
}

.hs_analyze3 .box .item3 .right p {
	top: -58px;
	left: 96px;
}

.hs_analyze3 .box .item3 .right .g-rect-fill {
	fill: none;
	stroke-dasharray: 0, 300;
	stroke-dashoffset: 0;
	animation: xian1 2s linear 1.5s infinite;
}

.hs_analyze3 .box .item4 {
	top: 0;
	right: 175px;
}

.hs_analyze3 .box .item4 .left {
	text-align: left;
}

.hs_analyze3 .box .item4 .right {
	margin-left: 0;
	margin-right: 40px;
}

.hs_analyze3 .box .item4 .right p {
	top: 40px;
	left: -186px;
}

.hs_analyze3 .box .item4 .right .g-rect-fill {
	fill: none;
	stroke-dasharray: 0, 300;
	stroke-dashoffset: 0;
	animation: xian1 2s linear 1.2s infinite;
}

.hs_analyze3 .box .item5 {
	top: 295px;
	right: 150px;
}

.hs_analyze3 .box .item5 .left {
	text-align: left;
}

.hs_analyze3 .box .item5 .right {
	margin-left: 0;
	margin-right: 40px;
}

.hs_analyze3 .box .item5 .right p {
	top: 6px;
	left: -184px;
}

.hs_analyze3 .box .item5 .right .g-rect-fill {
	fill: none;
	stroke-dasharray: 0, 300;
	stroke-dashoffset: 0;
	animation: xian1 2s linear 0.6s infinite;
}

@keyframes xian1 {
	0% {
		stroke-dasharray: 0, 300;
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dasharray: 100, 300;
		stroke-dashoffset: -274;
	}
}

@keyframes xian2 {
	0% {
		stroke-dasharray: 100, 350;
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dasharray: 100, 350;
		stroke-dashoffset: 450;
	}
}
.w1520 {
	max-width: 1520px;
	margin: auto;
	width: 90%;
}
.p78 {
    padding-top: 4.875rem;
}

.flexEnd {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.flexStart {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
.index_title h5 {
	color: #061e39;
	font-size: 2.625rem;
	text-align: center;
}

.index_title h5 span {
	font-family: 'gilroyBold';
}
</style>