<!--
 * @Author: 张新泽 11746616+zhangxinzesad@user.noreply.gitee.com
 * @Date: 2024-05-29 15:23:14
 * @LastEditors: 张新泽 11746616+zhangxinzesad@user.noreply.gitee.com
 * @LastEditTime: 2024-05-30 14:22:12
 * @FilePath: \中融科技官网\src\views\Layout\Layout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
 
    <router-view  />
   
</template>

<script>
export default {

}
</script>

<style>

</style>