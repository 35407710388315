
<template>
  <footer class="w-full text-zinc-500 bg-black">
    <div class="pt-8 mx-auto max-w-[86.67%] w-[1200px]">
      <div class="flex flex-wrap justify-between md:flex-nowrap">
        <dl class="md:mr-[6.6%] md:w-auto sm:w-2/4 sm:mb-0 mb-7 w-full pt-2">
          <dt class="text-lg font-bold mb-1 text-white">
            <img
              class="inline-block h-10"
              src="../../assets/logo2.png"
              width="93"
              height="40"
              alt="logo"
            />
          </dt>
          <!-- <dd class="mt-10 mb-4 min-w-[160px]">
            <a
              class="inline-block mr-4 align-middle w-[39px] h-[18px] bg-[url(/src/assets/icons/redbook.svg)] hover:bg-[url(/src/assets/icons/redbook-hover.svg)] bg-no-repeat bg-center"
              target="_blank"
              rel="nofollow"
              href="https://www.xiaohongshu.com/user/profile/6160f3a50000000002024c89"
            ></a>
            <a
              class="inline-block mr-4 align-middle w-10 h-[18px] bg-[url(/src/assets/icons/bili.svg)] hover:bg-[url(/src/assets/icons/bili-hover.svg)] bg-no-repeat bg-center"
              target="_blank"
              rel="nofollow"
              href="https://space.bilibili.com/1383135135?spm_id_from=333.337.search-card.all.click"
            ></a>
            <a
              class="inline-block mr-3 align-middle w-[37px] h-[17px] bg-[url(/src/assets/icons/zhihu.svg)] hover:bg-[url(/src/assets/icons/zhihu-hover.svg)] bg-no-repeat bg-center"
              target="_blank"
              rel="nofollow"
              href="https://www.zhihu.com/org/zuo-tang-4"
            ></a
            ><a
              class="inline-block mr-4 align-middle w-[21px] h-[18px] bg-[url(/src/assets/icons/tiktok.svg)] hover:bg-[url(/src/assets/icons/tiktok-hover.svg)] bg-no-repeat bg-center"
              target="_blank"
              rel="nofollow"
              href="https://www.douyin.com/user/MS4wLjABAAAAuC5DF3TqtnpW0XhKLVsfHfuFRmdLOAjgjbNq_7U-NQVBdPw2yk69O_1RzVm6JaHq"
            ></a>
          </dd> -->
          <dd></dd>
        </dl>

        <dl
          class="md:mr-[6.6%] md:w-auto sm:w-2/4 sm:mb-0 mb-7 w-full hide-new"
        >
          <dt class="text-lg font-bold mb-1 py-4 text-white">产品中心</dt>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="#/SmsNotification"
              >短信通知
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="#/SmsVerification"
              >短信验证码
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="#/SmsMarketing"
              >短信营销
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="#/MultimediaVideo"
              >视频彩信
            </a>
          </dd>
          <dd class="sm:hidden">
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="#/FlashMessage"
              >霸屏闪信
            </a>
          </dd>
          <dd class="sm:hidden">
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/crop-image"
              >抖音推广
            </a>
          </dd>
          <dd class="sm:hidden">
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/photo-enhancer"
              >朋友圈推广
            </a>
          </dd>
          <dd class="sm:hidden">
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/image-to-text"
              >小程序定制
            </a>
          </dd>
          <dd class="sm:hidden">
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/tools"
              >企业网站设计
            </a>
          </dd>
        </dl>

        <dl
          class="md:mr-[6.6%] md:w-auto sm:w-2/4 hidden sm:block sm:mb-0 mb-7 w-full hide-new"
        >
          <dt class="text-lg font-bold mb-1 py-4 text-white">&nbsp;</dt>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/compress-image"
              >抖音推广
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/crop-image"
              >朋友圈推广
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/photo-enhancer"
              >小程序定制
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href="/image-to-text"
              >企业网站设计
            </a>
          </dd>
        </dl>

        <dl class="md:mr-[6.6%] md:w-auto sm:w-2/4 sm:mb-0 mb-7 w-full">
          <dt class="text-lg font-bold mb-1 py-4 text-white">工具中心</dt>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href=""
            >
              视频去水印
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href=""
            >
              WORD转PDF
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href=""
            >
              图片变清晰
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href=""
            >
              图片设计
            </a>
          </dd>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              href=""
            >
              视频剪辑
            </a>
          </dd>
        </dl>

        <dl>
          <dt class="text-lg font-bold mb-1 py-4 text-white">关于佐糖</dt>
          <dd>
            <a
              class="block sm:py-3 py-[6px] leading-6 text-[15px] sm:text-[16px] text-zinc-300 hover:text-indigo-600"
              rel="nofollow"
              href="/about-us"
              >关于我们
            </a>
            <div class="grid gap-5 relative">
              <div
                class="download-app block text-sm rounded-1 border-1 border-white flex items-center px-5 h-9 text-white hover:border-theme hover:text-theme lg:siblings-[#footer-ios-qrcode]:hover:block"
                rel="nofollow"
                target="_blank"
                href="https://download.aoscdn.com/down.php?softid=picwishphotoeditorpro-pin&amp;os=ios"
              >
                联系我们
                <!--]-->
              </div>
              <!-- <a class="download-app block text-sm rounded-1 border-1 border-white flex items-center px-5 h-9 text-white hover:border-theme hover:text-theme lg:siblings-[#footer-app-qrcode]:hover:block" rel="nofollow" target="_blank" href="https://download.aoscdn.com/down.php?softid=picwishphotoeditorpro-pin&amp;os=android">
      Android 下载
      </a> -->

              <div
                class="absolute top-0 right-full -translate-x-2 w-30 hidden p-1 bg-white rounded-sm shadow"
                id="footer-ios-qrcode"
              >
                <img src="../../assets/gzhrwm.jpg" alt="" />
              </div>
            </div>
          </dd>
        </dl>
      </div>

      <div class="pt-11 pb-2.5 text-center text-base text-zinc-500">
        <!-- <p class="inline-block mr-5 mb-0">
        Copyright ©2024 佐糖 保留所有权利
      </p> -->
        <!-- <a class="inline-block px-2.5 border-r border-solid border-[#666666] hover:text-indigo-600" href="https://beian.miit.gov.cn/">粤ICP备14077691号
        </a><a class="inline-block px-2.5 border-r border-solid border-[#666666] hover:text-indigo-600" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009259">
          <img class="inline-block" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAC2VBMVEUAAAD+/ODz6Kr//+PeqFfYrn3x167oxnyaaVzhs2ifaFXbrGLkvFnpyF7v2X/kwm3cp1nhsGfqw3rZqG3ntVzjrFPt3oDjvGnfr2fbnFGti3q0lH7ktoLryXn9v1T4znr/74bnvGz034v+2I/ktoDz6ZLkwY/Dfz7buoftzYbq2IPr0pjs3bLv6KPRrnbKhFv79ND488n/+dDZr4Lx38f/+cH/95f42oL7/97s2Y3++uzw1rvTk3DmuloAAHkBAm7uzWYAAGXktV3qvFr/0ljksE7fo0rWHxhrdocAAIAABHf143Pyy27w1GwGA2jtymHpwWDqxV/qyVyTeFrrwFflwFPislP+xVLpsErbmUfVkEbysETemUTpgj7ThT3XdTg5FDjdhTXWZTDaTCm7TCbTOCLXPiD9LA/QFg3UAwnOAQOEj5kcPpdyhZSptJEACJFpfo4AG44XMInFvYfTvIejmYSVkINyeoJzdoK9un6SjX7FrnwAEHp8enny2HjWwHjKtnhcX3jYzHeNhnfu2HWUjHWsonPNwnH70m9WTm8AAW//723pym3dtmn/0mbnxGa0o2ZeWWb8zGT/4mPtwmJuYmL/22D/vmB5ZGC9kF7/2l0MAF3uyFqnjVn4xFjYnli0mVi5i1jiqVfyyVbmtlbXkVNUOFPlvFLpt1LNrFKjfVLuvlBgHlDsuU/ouU9ONU/ov05ODk7/2E02Gk3jqkqEaUr/tUngjkf7n0bXikb6xERCJETdn0LckUG1gD/ooD3Ulj3jkz3TZT3WjjzOeDqBWDr3pDnglTlMADnbbTf2gjbkbzaTYDZpAjbplzTtcTTEazPXXzOeXzDscS3MPi38jizJWSrVSCrrXynzfCjVdCjZRyjTQCbFUiTlYCPXPSHLPSHWMR/wXh7iRh7GPh3PLBrSIRrWGhfMJxPGJxPRDBG/ABG2ABCxDg7BDAvEGArZAAbJAALPAABwPJeHAAAAPXRSTlMACEIaxqxp+/v59/X08Ozs6+fm5eTk4eDb0tHLx8TDwb68urKxq5+cmJWTjIN8b11cWkxIQUE1MCknIRwUAZA0XgAAAXFJREFUGNNigAJmNmkpNmYGZGDKynXp6NZVHKxGCDHGmQlnLty73NuWsJARrlV+ffDNzNTkrB15y5Shohacmydefeb+9HbGu+keu4UswYJmrZ5zH2U+fP4q9eONSvtmc5CYtVyV177HH9zvp7l/SmvqqlWwAgrqHSv1XHAq69qB0O0Z59vty1x0gIIa56rzp+5KeZIUf3Ln5Bl2xdFqQEHhRJ8c+5TY6w8u3jl9yK+o7rgEUBAw7iPbcu3epCfdjY9Nf+1XEB4nDhRU2TOppPDE2/cvXyRfOVvT0BGmCRSU2buoL9vL0XHjmtmOduWznLbIAgXZ5y896FMf7DFhZWNnxZT9ka58QEHD5W5Rt+ZN2xQe7dLtkhgVFsQEcr0Bb2jcYQdnV1sH35jIHn6wGIOJYFDgkpDAAH9nN1vffgGIoJJ3i5OTs2vAihg3W1sHb1VIaGrxrFu8OmJtRMicDf5iurAAtdFXZ5EUFWFR1DYG8wEUrIUMZ1ticAAAAABJRU5ErkJggg==" width="20" height="20" alt="police-record">
          粤公网安备 44030502009259
        </a>
      <a class="inline-block px-2.5 border-r border-solid border-[#666666] hover:text-indigo-600" href="/app-terms">服务协议
      </a>
      <a class="inline-block px-2.5 border-r border-solid border-[#666666] hover:text-indigo-600" href="/app-privacy">隐私
      </a>
      <a class="inline-block px-2.5 border-r border-solid border-[#666666] hover:text-indigo-600" href="/cookies-policy">Cookies政策
      </a>
      <a class="inline-block px-2.5 hover:text-indigo-600" href="/license-agreement">最终用户许可协议
      </a> -->
        <!-- <a class="inline-block px-2.5 border-l border-solid border-[#666666] hover:text-indigo-600" href="/faq/frequently-asked-questions.html#_1">产品功能
        </a><a class="inline-block px-2.5 border-x border-[#666666] hover:text-indigo-600" href="https://www.12377.cn/" target="_blank">
            网上有害信息举报
          </a><a class="inline-block px-2.5  hover:text-indigo-600" href="https://szwljb.sz.gov.cn/" target="_blank">
            深圳市互联网违法和不良信息举报
          </a>
          <div class="text-center">
          <a href="https://download.aoscdn.com/down.php?softid=picwishphotoeditorpro-pin" rel="nofollow" class="inline-block px-[10px]">
            中融科技
          </a>
          <a class="inline-block px-[10px] border-r border-[#666666]" href="/app-permission">应用权限</a> -->
        <p class="inline-block mr-5 ml-[10px] mt-[5px]">
          中融网络科技发展（江苏）有限公司
        </p>
        <p class="inline-block mr-5 ml-[10px] mt-[5px]">
          地址：淮阴区南昌北路江苏淮阴服务外包产业园中业广场1楼
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../../style/btn.css";

@media (max-width: 600px) {
  .hide-new {
    display: none !important;
  }
}
a {
    color: #999999;
    text-decoration: none;
}
</style>